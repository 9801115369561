import React, {useState} from 'react';
import {FaCheck} from "react-icons/fa6";
import {Check, SortOption, SortOptions} from "./CategoryStyle";

const Filter = ({ fetchProducts }) => {
    const [selectedOption, setSelectedOption] = useState('Prix croissant');

    const handleOptionChange = (option) => {
        if (selectedOption === option) {
            // Si l'élément sélectionné est déjà celui qui a été cliqué, décochez-le
            setSelectedOption(null);
            fetchProducts({ orderBy: null }); // Assurez-vous de gérer null correctement dans votre logique d'appel GraphQL
        } else {
            setSelectedOption(option);
            fetchProducts({orderBy: option});
        }
    };

    return (
        <SortOptions>
            <SortOption onClick={() => handleOptionChange('asc')}>
                Prix croissant
                {selectedOption === 'asc' && <Check><FaCheck/></Check>}
            </SortOption>
            <SortOption onClick={() => handleOptionChange('desc')}>
                Prix décroissant
                {selectedOption === 'desc' && <Check><FaCheck/></Check>}
            </SortOption>
        </SortOptions>
    );
};

export default Filter;
