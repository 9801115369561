import React, {useState} from 'react';
import {FaCheck} from "react-icons/fa6";
import {Check, SortOption, SortOptions} from "./CategoryStyle";

const State = ({fetchProducts}) => {
    const [selectedState, setSelectedState] = useState('Nouveau');

    const handleStateChange = (state) => {
        if (selectedState === state) {
            // Si l'élément sélectionné est déjà celui qui a été cliqué, décochez-le
            setSelectedState(null);
            fetchProducts({ etat: null }); // Assurez-vous de gérer null correctement dans votre logique d'appel GraphQL
        } else {
            setSelectedState(state);
            fetchProducts({ etat: state });
        }
    };
    return (
        <SortOptions>
            <SortOption onClick={() => handleStateChange('Neuf')}>
                Neuf
                {selectedState === 'Neuf' && <Check><FaCheck/></Check>}
            </SortOption>
            <SortOption onClick={() => handleStateChange('Bon')}>
                Bon
                {selectedState === 'Bon' && <Check><FaCheck/></Check>}
            </SortOption>
            <SortOption onClick={() => handleStateChange('Passable')}>
                Passable
                {selectedState === 'Passable' && <Check><FaCheck/></Check>}
            </SortOption>
        </SortOptions>
    );
};

export default State;
