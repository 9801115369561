import React from 'react';
import styled from 'styled-components';
import { ClimbingBoxLoader } from 'react-spinners';
import {palettes} from "../Colors";

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  grid-column: 3;
`;

const Spinner = () => (
    <SpinnerContainer>
        <ClimbingBoxLoader size={25} color={palettes.primary.light} loading={true} />
    </SpinnerContainer>
);

export default Spinner;
