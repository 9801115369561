export const palettes = {
  primary: {
    light: '#3498db',
    dark: '#2980b9'
  },
  secondary: {
    light: '',
    dark: ''
  },
  button: '',
}

export default {palettes}
