// API/getOrders.js

import { gql } from '@apollo/client';

export const GET_ORDERS = gql`
  query GetOrders {
    getOrders {
      id
      name
      firstname
      email
      phone
      address
      city
      accepted
      date
      isDeliveryChecked
      furniture {
        id
        nom
        description
        prix
        taille
        image
        images
        meubleType {
          nom
        }
        disponible
        couleur
        materiau
        etat
        created_at
        updated_at
      }
    }
  }
`;


export default GET_ORDERS;
