import React from 'react';
import styled from 'styled-components';
import ContactInfo from './ContactInfo';
import ContactCard from './ContactCard';
import View from '../Common/View';

const Header = styled.header`
  text-align: center;
  margin-bottom: 20px;
  padding: 40px 0;
  background-color: #ffffff; /* Fond blanc pour la partie header */
`;

const HeaderTitle = styled.h1`
  font-size: 2.5em;
  color: #333;
`;

const ContactSection = styled.section`
  padding: 20px;
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ContactContent = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin-right: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-right: 0;
  }
`;

const Contact = () => {
    return (
        <View>
            <Header>
                <HeaderTitle>Nous Contacter</HeaderTitle>
            </Header>
            <ContactSection>
                <ContactContent>
                    <ContactInfo />
                    <ContactCard />
                </ContactContent>
            </ContactSection>
        </View>
    );
};

export default Contact;
