import React from 'react';
import Lottie from 'lottie-react';
import animationData from './lotties/Prepare.json';

const Prepare = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true
    };

    return (
        <div style={styles.container}>
            <Lottie
                options={defaultOptions}
                animationData={animationData}
                height={'auto'}
                width={'100%'}
            />
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '500px', // Ajustez la hauteur selon vos besoins
        width: '80%', // Ajustez la largeur selon vos besoins
        padding: '5% 0',
        background: 'white', // Couleur de fond
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', // Ombre
        borderRadius: '10px', // Coins arrondis
        // Ajoutez d'autres styles CSS personnalisés selon vos préférences
    },
};

export default Prepare;
