import React, {useEffect, useState} from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import styled from 'styled-components';
import View from '../../Common/View';
import {useSelector} from "react-redux";
import AppointmentForm from "./AppointmentForm";
import StripePaymentForm from "./StripePaymentForm";
import moment from "moment";
import 'moment/locale/fr'
import {Link} from "react-router-dom";
import {palettes} from "../../Common/Colors";

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  margin: 0 20px;
  width: 45%;
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  align-items: center;
  margin: 0 25px;
  border-bottom: 1px solid #ccc;
  width: 90%;

  &:last-child {
    border: none;
  }
`;

const StepHeader = styled.header`
  width: 100%;
  text-align: left;
  background-color: #ffffff;
`;

const StepTitle = styled.h2`
  font-size: ${(props) => (props.currentStep ? '2em' : '1.5em')};
  color: ${(props) => (props.currentStep ? 'black' : '#999')};
`;

const PaymentRecap = styled.div`
  flex: 1;
  max-width: 325px;
  margin-left: 20px;
  height: fit-content;
  background-color: #fafafa;
  border-radius: 10px;
  box-shadow: 7px 7px 7px rgba(51, 153, 255, 0.15);
  padding: 10px;
  text-align: left;

  h3 {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #bbb;
  }
`;

const Product = styled.div`
  width: min-content;
  margin: auto;
  padding-bottom: 10px;
`;

const Products = styled.div`
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 15px;
`;

const ProductImage = styled.img`
  width: 300px;
  height: auto;
  margin-right: 10%;

  @media (max-width: 600px) {
    width: 100%;
    margin-right: 0;
  }
`;

const RecapProductContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

const RecapProductInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const RecapProductName = styled.span`
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 5px;
`;

const RecapProductPrice = styled.span`
  font-size: 1.2em;
`;

const ProductName = styled.span`
  display: block;
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px;
`;

const TotalAmount = styled.span`
  display: grid;
  grid-template-columns: 1fr max-content;
  font-weight: bold;
  font-size: 1.5em;
  padding: 10px 20px;
  border-top: 1px solid #bbb;
`;

const RecapContainer = styled.div`
  padding: 20px;
`;

const DateContainer = styled.div`
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
  font-size: 1.6em;
`;

const ThankYouMessage = styled.p`
  font-size: 1.5em;
  color: #333;
  margin-top: 30px;
  text-align: center;
`;

const ReturnButton = styled(Link)`
  display: block;
  width: fit-content;
  text-decoration: none;
  background-color: ${palettes.primary.light};
  color: white;
  border: none;
  border-radius: 5px;
  padding: 15px 30px;
  font-size: 1.2em;
  cursor: pointer;
  margin-top: 40px;
  transition: background-color 0.3s; /* Ajout de la transition */

  &:hover {
    background-color: ${palettes.primary.dark}; /* Couleur de fond au survol */
  }
`;

const Form = styled.form`
  width: 80%;
  margin: 0 auto 30px auto;
  padding: 20px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: #fff;
  text-align: center;
`;

const Label = styled.label`
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
  text-align: left;
`;

const Input = styled.input`
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 95%;
`;

const Checkbox = styled.input`
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 4px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;

  &:checked {
    border-color: ${palettes.primary.light};
    background-color: ${palettes.primary.light};
  }

  &:checked::after {
    content: '\u2713';
    font-size: 14px;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;


const stripePromise = loadStripe('votre_clé_api_publique');

const Step = ({children, title, currentStep, stepNumber}) => {
  return (
    <StepContainer>
      <StepHeader>
        <StepTitle currentStep={currentStep === stepNumber}>{title}</StepTitle>
      </StepHeader>
      {currentStep === stepNumber && children}
    </StepContainer>
  );
};

const PaymentPage = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isDeliveryChecked, setDeliveryChecked] = useState(false);
  const cart = useSelector((state) => state.products.cart);
  const purchased = useSelector((state) => state.products.purchased);
  const [, setPaymentSuccess] = useState(false);
  const [deliveryInfo, setDeliveryInfo] = useState({
    address: '',
    city: '',
  });
  const [customerInfo, setCustomerInfo] = useState({
    name: '',
    firstname: '',
    email: '',
    phone: '',
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const redirectStatus = urlParams.get('redirect_status');

    if (redirectStatus === 'succeeded') {
      setPaymentSuccess(true);
      setCurrentStep(3)
    }
  }, []);

  const handleCheckboxChange = () => {
    setDeliveryChecked(!isDeliveryChecked);
  };

  const handleInputChange = (e) => {
    const {name, value} = e.target;
    setDeliveryInfo({...deliveryInfo, [name]: value});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  let totalAmount = cart.cartItems
    .reduce((acc, item) => acc + item.prix * item.quantity, 0)
    .toFixed(2)
  let totalAmountPurchased = purchased.purchasedItems
    .reduce((acc, item) => acc + item.prix * item.quantity, 0)
    .toFixed(2)

  const handleNextStep = (data) => {
    // Cette fonction sera appelée depuis AppointmentForm avec la date sélectionnée
    const d = moment(data.selectedDate).format('DD/MM/YYYY')
    const t = moment(data.selectedTime).format('HH:mm')
    moment.locale('fr')
    const rdvDate = moment(
      `${moment(d, 'DD/MM/YYYY').format('DD/MM/YYYY')} ${moment(t, 'HH:mm').format('LT')}`,
      'DD/MM/YYYY HH:mm'
    ).format('LLL')
    setCustomerInfo({
      name: data.name,
      firstname: data.firstname,
      email: data.email,
      phone: data.phoneNumber
    });
    setSelectedDate(rdvDate);
    setCurrentStep(2); // Passez à l'étape suivante
  };

  return (
    <View>
      <MainContainer>
        <StepsContainer>
          <Step
            title="Prendre rendez-vous"
            currentStep={currentStep}
            stepNumber={1}
            onNextStep={() => setCurrentStep(2)}
          >
            <AppointmentForm onNextStep={handleNextStep}/>
          </Step>
          <Step title="Paiement" currentStep={currentStep} stepNumber={2} onNextStep={() => setCurrentStep(3)}
                onPrevStep={() => setCurrentStep(1)}>
            <Elements stripe={stripePromise}>
              <div style={{width: '100%'}}>
                <h3>Livraison</h3>
                <Form onSubmit={handleSubmit}>
                  <Label>
                    <Checkbox
                      type="checkbox"
                      checked={isDeliveryChecked}
                      onChange={handleCheckboxChange}
                    />
                    Livraison à domicile
                  </Label>

                  {isDeliveryChecked && (
                    <>
                      <Label>
                        Adresse:
                        <Input
                          type="text"
                          name="address"
                          value={deliveryInfo.address}
                          onChange={handleInputChange}
                          required
                        />
                      </Label>

                      <Label>
                        Ville:
                        <Input
                          type="text"
                          name="city"
                          value={deliveryInfo.city}
                          onChange={handleInputChange}
                          required
                        />
                      </Label>
                      <h4 style={{color: "red"}}>Attention ! Des frais de livraison seront à prévoir !</h4>
                    </>
                  )}
                </Form>
              </div>
              <StripePaymentForm
                totalAmount={totalAmount}
                onNextStep={() => setCurrentStep(3)}
                selectedDate={selectedDate}
                isDeliveryChecked={isDeliveryChecked}
                deliveryInfo={deliveryInfo}
                customerInfo={customerInfo}
                furnitures={cart.cartItems}
              />
            </Elements>
          </Step>
          <Step
            title="Récapitulatif"
            currentStep={currentStep}
            stepNumber={3}
            onNextStep={() => setCurrentStep(4)}
            onPrevStep={() => setCurrentStep(2)}
          >
            <RecapContainer>
              {purchased?.purchasedItems?.map((item) => (
                <RecapProductContainer key={item.id}>
                  <ProductImage src={item.image} alt={item.nom}/>
                  <RecapProductInfo>
                    <RecapProductName>{item.nom}</RecapProductName>
                    <RecapProductPrice>
                      {item.prix} €
                    </RecapProductPrice>
                  </RecapProductInfo>
                </RecapProductContainer>
              ))}
              <TotalAmount>
                <strong>Total</strong>
                {purchased.totalAmount ? purchased.totalAmount : totalAmountPurchased} €
              </TotalAmount>
              <ThankYouMessage>
                Merci pour votre achat ! Votre rendez-vous est fixé pour
                le <DateContainer>{selectedDate}</DateContainer>
              </ThankYouMessage>
            </RecapContainer>
            <ReturnButton to="/products">Retour à la page des produits</ReturnButton>
          </Step>

        </StepsContainer>
        {currentStep !== 3 && <PaymentRecap>
          <h3>Récapitulatif de l'achat</h3>
          <Products>
            {cart.cartItems?.map((item) => (
              <Product key={item.id}>
                <ProductName>{item.nom} - {item.prix.toFixed(2)}€</ProductName>
                <ProductImage src={item.image} alt={item.nom}/>
              </Product>
            ))}
          </Products>
          <TotalAmount>
            <strong>Total</strong>
            {cart.totalAmount !== 0 ? cart.totalAmount : totalAmount} €
          </TotalAmount>
        </PaymentRecap>}
      </MainContainer>
    </View>
  );
};

export default PaymentPage;
