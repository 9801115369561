import React, { useState } from 'react';
import { FaCheck } from "react-icons/fa6";
import { ButtonDelete, ButtonSave, Check, SortOption, SortOptions } from "./CategoryStyle";
import { useQuery } from '@apollo/client';
import GET_MEUBLES_TYPES from "../../CreateFurniture/Redux/API/getMeublesTypes";

const Type = ({ setCategoryStates, toggleCategory, fetchProducts }) => {
    const { loading, error, data } = useQuery(GET_MEUBLES_TYPES);

    const [selectedTypes, setSelectedTypes] = useState([]); // Utilisez un tableau pour suivre les types de meubles sélectionnés

    const handleTypeChange = (type) => {
        if (selectedTypes.includes(type)) {
            setSelectedTypes(selectedTypes.filter((t) => t !== type)); // Retire le type si déjà sélectionné
        } else {
            setSelectedTypes([...selectedTypes, type]); // Ajoute le type s'il n'est pas encore sélectionné
        }
        // Fermer la catégorie "Trier" lors de la sélection d'une option
        setCategoryStates((prevState) => ({
            ...prevState,
            type: false,
        }));
    };

    if (loading) {
        return <p>Chargement en cours...</p>;
    }

    if (error) {
        return <p>Une erreur s'est produite : {error.message}</p>;
    }

    const furnitureTypes = data.getMeublesTypes;

    return (
        <React.Fragment>
            <SortOptions>
                {furnitureTypes.map((type) => (
                    <SortOption key={type.id} onClick={() => handleTypeChange(type.id)}>
                        {type.nom}
                        {selectedTypes.includes(type.id) && <Check><FaCheck /></Check>}
                    </SortOption>
                ))}
            </SortOptions>
            <div>
                <ButtonDelete
                    onClick={(e) => {
                        e.stopPropagation();
                        setSelectedTypes([]);
                    }}
                >
                    Effacer
                </ButtonDelete>
                <ButtonSave
                    onClick={(e) => {
                        e.stopPropagation();
                        toggleCategory('type');
                        // Vous pouvez ici effectuer un appel GraphQL pour mettre à jour les produits avec les types sélectionnés
                        fetchProducts({ meuble_type_id: selectedTypes });
                    }}
                >
                    Enregistrer
                </ButtonSave>
            </div>
        </React.Fragment>
    );
};

export default Type;
