// getOrdersActions.js

import GET_ORDERS from "../API/getOrders";
import client from "../../../Client/client";

export const GET_ORDERS_REQUEST = 'GET_ORDERS_REQUEST';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAILURE = 'GET_ORDERS_FAILURE';

export const getOrdersRequest = () => ({
    type: GET_ORDERS_REQUEST,
});

export const getOrdersSuccess = (orders) => ({
    type: GET_ORDERS_SUCCESS,
    payload: orders,
});

export const getOrdersFailure = (error) => ({
    type: GET_ORDERS_FAILURE,
    payload: error,
});

export const getOrders = () => async (dispatch) => {
    dispatch(getOrdersRequest());

    try {
        const response = await client.query({
            query: GET_ORDERS,
        });

        const orders = response.data.getOrders; // Assurez-vous d'adapter cela selon votre schéma GraphQL
        dispatch(getOrdersSuccess(orders));
    } catch (error) {
        dispatch(getOrdersFailure(error.message));
    }
};
