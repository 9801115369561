import React from 'react';
import styled from 'styled-components';
import { FaLocationDot, FaPhone } from 'react-icons/fa6';
import { MdEmail } from "react-icons/md";

const ContactInfoContainer = styled.div`
  padding: 20px;
  font-size: 1.2em;
  background-color: #fafafa;
  border-radius: 10px;
  box-shadow: 7px 7px 7px rgba(51, 153, 255, 0.15);
  width: 25%;
  height: fit-content;

  @media (max-width: 768px) {
    width: auto;
    margin: 5px;
  }
`;

const ContactMap = styled.div`
  width: 100%;
  height: 300px;
  margin-top: 20px;
`;

const ContactInfo = () => {
    return (
        <ContactInfoContainer>
          <p><FaLocationDot size={25} /><span style={{marginLeft: 20}}>123 Rue des Meubles, 57000 Metz, France</span></p>
          <p><MdEmail size={25} /><span style={{marginLeft: 20}}>jungledumonde@gmail.com</span></p>
          <p><FaPhone size={25} /><span style={{marginLeft: 20}}>06.42.16.22.76</span></p>
            <ContactMap>
                <iframe
                    title={"maps"}
                    width={"100%"}
                    height={"300"}
                    style={{border:0, borderRadius: 10}}
                    src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d83585.37357843714!2d6.1962337!3d49.10482499999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4794dc1b6074b6a9%3A0x596be4b635bba669!2sMetz!5e0!3m2!1sfr!2sfr!4v1697568178506!5m2!1sfr!2sfr"}
                    allowFullScreen
                />
            </ContactMap>
        </ContactInfoContainer>
    );
};

export default ContactInfo;
