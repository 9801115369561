// actions/Actions.js


// Action type pour enregistrer les données de l'utilisateur
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
// Action creator pour stocker les informations de l'utilisateur et le token dans le store
export const loginSuccess = (user) => {
    return {
        type: 'LOGIN_SUCCESS',
        user: user,
    };
};

// Action type pour enregistrer les données de l'utilisateur
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';

// Action creator pour enregistrer les données de l'utilisateur
export const registerSuccess = (user) => ({
    type: REGISTER_SUCCESS,
    payload: user,
});

// Action type pour enregistrer les données de l'utilisateur
export const EDIT_SUCCESS = 'EDIT_SUCCESS';

// Action creator pour enregistrer les données de l'utilisateur
export const editSuccess = (user) => ({
    type: EDIT_SUCCESS,
    payload: user,
});

// Action type pour enregistrer les données de l'utilisateur
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';

// Action creator pour enregistrer les données de l'utilisateur
export const getUserSuccess = (user) => ({
    type: GET_USER_SUCCESS,
    payload: user,
});

export const LOGOUT = 'LOGOUT';

export const logout = (user) => ({
    type: REGISTER_SUCCESS,
        payload: user,
});

export const CLOSE_CART = 'CLOSE_CART';

export const closeCart = (open) => ({
    type: CLOSE_CART,
        payload: open,
});
