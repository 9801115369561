// getOrdersReducer.js

import {
    GET_ORDERS_REQUEST,
    GET_ORDERS_SUCCESS,
    GET_ORDERS_FAILURE,
} from '../Actions/getOrdersActions';

const initialState = {
    loading: false,
    orders: [],
    error: null,
};

const getOrdersReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ORDERS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_ORDERS_SUCCESS:
            return {
                ...state,
                loading: false,
                orders: action.payload,
                error: null,
            };
        case GET_ORDERS_FAILURE:
            return {
                ...state,
                loading: false,
                orders: [],
                error: action.payload,
            };
        default:
            return state;
    }
};

export default getOrdersReducer;
