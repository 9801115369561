import {gql} from '@apollo/client';

// Définissez votre mutation GraphQL pour la connexion de l'utilisateur
export const LOGIN_USER = gql`
  mutation loginUser($email: String!, $password: String!) {
    loginUser(email: $email, password: $password) {
      id
      name
      email
      token,
      role
    }
  }
`;

export default LOGIN_USER;
