import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {FaSearch} from 'react-icons/fa';
import View from '../Common/View';
import ProductFilters from './ProductFilters';
import {useQuery} from '@apollo/client';
import GET_FURNITURES from "./Redux/API/getFurnitures";
import Spinner from "../Common/Spinner";
import client from "../Client/client";
import {palettes} from "../Common/Colors";

const Header = styled.header`
  text-align: center;
  margin-bottom: 20px;
  padding: 40px 0;
  background-color: #ffffff; /* Fond blanc pour la partie header */
`;

const HeaderTitle = styled.h1`
  font-size: 2.5em;
  color: #333;
`;

const SearchBar = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 50px;
  height: 45px;
  min-width: 325px; /* Ajustez cette valeur selon vos besoins */
  width: 25%; /* Largeur initiale de 25% */
  margin: 0 auto; // Centrer la barre de recherche horizontalement
  padding: 0 15px;
  transition: width 0.3s; // Ajouter une transition

  &:focus-within {
    width: 50%; // Augmentez la largeur de la barre de recherche lorsqu'elle est en focus
    border: 1px solid ${palettes.primary.dark};
  }
`;

const SearchInput = styled.input`
  flex: 1;
  border: none;
  font-size: 1.2em;

  &:focus {
    outline: none; // Supprimer la bordure du focus
  }
`;

const SearchButton = styled.button`
  background-color: ${palettes.primary.dark}; // Fond bleu
  color: white;
  border: none;
  border-radius: 50%; // Rendre le bouton rond
  margin-right: -7px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProductContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
  height: 100%;
`;

const ProductCard = styled(Link)`
  border: 1px solid #e0e0e0;
  padding: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 6px 2px rgba(51, 153, 255, 0.1);
  text-decoration: none;
  color: black;
  transition: box-shadow 0.3s ease, transform 0.3s ease;

  &:hover {
    box-shadow: 0 8px 8px 4px rgba(51, 153, 255, 0.2);
    color: ${palettes.primary.dark};
    transform: scale(1.05);
  }
`;

const ProductImageContainer = styled.div`
  width: 100%;
  height: 150px;
  text-align: center;
  margin-bottom: 10px;
  transition: transform 0.3s ease;
`;

const ProductImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 250px;
  max-height: 150px;
  object-fit: cover;
  border: 1px solid #ccc;
  transition: transform 0.3s ease;
  overflow-clip-margin: unset;

  ${ProductCard}:hover & {
    transform: scale(1.1); // Increase size on hover
  }
`;

const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ProductTitle = styled.h3`
  font-size: 1.4em;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
`;

const ProductPrice = styled.p`
  margin: 5px 0 0 0;
  font-size: 1.2em;
  font-weight: bold;
`;

const ProductCondition = styled.p`
  margin: 0;
  font-size: 0.9em;
  color: black;
`;

const ProductDescription = styled.p`
  font-size: 1em;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const ProductPage = () => {
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [productName, setProductName] = useState();
    const {loading, error, data} = useQuery(GET_FURNITURES); // Utilisez la requête GraphQL
    const products = data?.getFurnitures || [];

    // Au chargement initial, affichez tous les produits
    useEffect(() => {
        if (filteredProducts?.length < 1 && !error) {
            fetchProducts(productName ? productName.trim() : null)
            setFilteredProducts(products)
        }
    }, [products]);

    const fetchProducts = async (tri) => {
        try {
            // Effectuer l'appel GraphQL
            const { data } = await client.query({
                query: GET_FURNITURES,
                variables: {
                    nom: tri?.trim()
                },
                fetchPolicy: 'no-cache',
            });

            // Mettez à jour la liste des produits avec les données reçues
            setFilteredProducts(data.getFurnitures);
        } catch (error) {
            console.error('Erreur lors de l\'appel GraphQL :', error);
        }
    };

    return (
        <View>
            <Header>
                <HeaderTitle>Nos Meubles</HeaderTitle>
                <SearchBar>
                    <SearchInput
                        type="text"
                        placeholder="Rechercher des meubles par nom"
                        value={productName}
                        onChange={(e) => setProductName(e.target.value.trim())}
                    />
                    <SearchButton onClick={() => fetchProducts(productName)}>
                        <FaSearch/>
                    </SearchButton>
                </SearchBar>
                <ProductFilters setProducts ={setFilteredProducts} />
            </Header>
            <div style={{height: '100%'}}>
                <ProductContainer>
                    {
                        error ? <p>Une erreur s'est produite : {error.message}</p>
                            : loading ? <React.Fragment>
                                    <p style={{marginLeft: 15, minWidth: 100, display: 'grid', gridAutoColumns: 'max-content'}}>
                                        Chargement en cours des produits...
                                    </p>
                                    <Spinner/>
                                </React.Fragment>
                                : filteredProducts.map((product) => {
                                return product.disponible &&  (
                                    <ProductCard to={`/products/${product.id}`} key={product.id}>
                                        <ProductImageContainer>
                                            <ProductImage src={product.image} alt={product.nom}/>
                                        </ProductImageContainer>
                                        <ProductDetails>
                                            <ProductTitle>{product.nom}</ProductTitle>
                                            <ProductInfo>
                                                <ProductPrice>{product.prix.toFixed(2)} €</ProductPrice>
                                                <ProductCondition>
                                                    {product.disponible ? 'En bon état' : 'Usagé'}
                                                </ProductCondition>
                                            </ProductInfo>
                                            <ProductDescription>{product.description}</ProductDescription>
                                        </ProductDetails>
                                    </ProductCard>
                                  )
                              }
                            )
                    }
                </ProductContainer>
            </div>
        </View>
    );
};

export default ProductPage;
