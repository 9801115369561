import React from 'react';
import styled from 'styled-components';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import {palettes} from "../Common/Colors";

const Category = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  margin: 0 20px;
  position: relative; /* Ajout de la position relative */

  @media (max-width: 825px) {
    margin: 0 10px;
  }
`;

const CategoryName = styled.h3`
  display: flex;
  flex-direction: row;
  font-size: 1.2em;
  margin: auto;
  color: ${palettes.primary.light};
  padding: 5px 15px;
  background-color: #fff;
  border: ${(props) => (props.isOpen ? '2px solid' : '1px solid')} ${palettes.primary.light};
  border-bottom: ${(props) => (props.isOpen ? '0px solid #fff' : `1px solid ${palettes.primary.light}`)};
  height: ${(props) => (props.isOpen ? '35px' : '30px')};
  z-index: 10;

  &:hover {
    border: 2px solid ${palettes.primary.dark};
    border-bottom: ${(props) => (props.isOpen ? '0px solid #fff' : `2px solid ${palettes.primary.dark}`)};
  }

  @media (max-width: 825px) {
    font-size: 0.8em;
    padding: 1px 5px;
    height: ${(props) => (props.isOpen ? '20px' : '25px')};
  }
`;


const Icon = styled.div`
  font-size: 1.2em;
  margin-left: 10px;
  display: flex;
  align-items: center;

  @media (max-width: 825px) {
    font-size: 0.8em;
    margin-left: 1px;
    height: 20px;
  }
`;

const Popup = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: -2px;
  padding: 10px;
  border: 2px solid #3498db;
  background-color: #fff;
  z-index: 100;
  width: max-content;

  @media (max-width: 825px) {
    margin-top: -13px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const FilterCategory = ({ name, isOpen, togglePopup, children, setPopupOpen, openCategory, second }) => {
  const { innerWidth: width } = window;
  if (second && width <= 1050) {
    return
  }
    const handleOptionClick = () => {
        togglePopup();
        setPopupOpen(false); // Ferme la popup
        if (openCategory !== name) {
            // Ferme la popup si la catégorie actuelle est différente de la openCategory
            setPopupOpen(false);
        }
    };

    return (
        <Category onClick={handleOptionClick}>
            <CategoryName isOpen={isOpen}>
                {name}
                <Icon>{isOpen ? <FaChevronUp /> : <FaChevronDown />}</Icon>
            </CategoryName>
            <Popup isOpen={isOpen}>{children}</Popup>
        </Category>
    );
};

export default FilterCategory;
