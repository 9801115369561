// getUser.graphql

import { gql } from '@apollo/client';

// Définissez votre requête GraphQL pour obtenir les détails d'un utilisateur par ID
export const GET_USER = gql`
  query getUser($id: Int!) {
    user(id: $id) {
      id
      name
      email
      role
      # Autres champs de l'utilisateur que vous souhaitez récupérer
    }
  }
`;

export default GET_USER;
