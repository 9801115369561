import { gql } from '@apollo/client';

export const GET_FURNITURE = gql`
  query getFurniture(
    $id: Int
  ) {
    getFurniture(
      id: $id
    ) {
      id
      nom
      description
      prix
      taille
      image
      images
      meubleType {
        id
        nom # Si vous souhaitez récupérer des détails sur le type de meuble
      }
      disponible,
      couleur,
      etat,
      materiau
      # Autres champs du meuble que vous souhaitez récupérer
    }
  }
`;

export default GET_FURNITURE;
