import { gql } from '@apollo/client';

export const ACCEPT_COMMAND = gql`
  mutation AcceptOrder($orderId: Int!) {
    acceptOrder(orderId: $orderId) {
      id
      name
      firstname
      email
      phone
      address
      city
      accepted
      date
      isDeliveryChecked
      furniture {
        id
        nom
        description
        prix
        taille
        image
        images
        meubleType {
          nom
        }
        disponible
        couleur
        materiau
        etat
        created_at
        updated_at
      }
    }
  }
`;
