// Profile.js

import React from 'react';
import styled from 'styled-components';
import {useMutation, useQuery} from "@apollo/client";
import EDIT_USER from "../Redux/API/editUser";
import GET_USER from "../Redux/API/getUser";
import {useDispatch} from "react-redux";
import {editSuccess} from "../Redux/Actions/auhtActions";
import {palettes} from "../Colors";

const PopupContainer = styled.div`
  position: absolute;
  top: 90px;
  right: 3%;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 7px 7px 7px rgba(51, 153, 255, 0.15);
  border-radius: 5px;
  padding: 20px;
  z-index: 1000;
  min-width: 250px;
`;

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UserName = styled.h3`
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 10px;
`;

const UserEmail = styled.p`
  color: #555;
  margin-bottom: 10px;
`;

const EditProfileButton = styled.button`
  width: 100%;
  padding: 12px;
  background: ${palettes.primary.light};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 15px;
  font-size: 1.2em;
  transition: background 0.3s;

  &:hover {
    background: ${palettes.primary.dark};
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  width: 100%;

  label {
    font-weight: bold;
    margin-bottom: 5px;
  }

  input {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 3px;
    margin-bottom: 10px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
`;

const SaveButton = styled.button`
  flex: 1;
  background: ${palettes.primary.light};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  padding: 10px;
  font-size: 1em;
  transition: background 0.3s;

  &:hover {
    background: ${palettes.primary.dark};
  }
`;

const CancelButton = styled.button`
  flex: 1;
  background: #e74c3c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  padding: 10px;
  font-size: 1em;
  margin-right: 10px;
  transition: background 0.3s;

  &:hover {
    background: #c0392b;
  }
`;

const Profile = ({ user }) => {
  const dispatch = useDispatch();
  const [editUserMutation] = useMutation(EDIT_USER);
  const { refetch } = useQuery(GET_USER, {
    variables: { id: user.id },
  });
  const [isEditing, setIsEditing] = React.useState(false);
  const [editedName, setEditedName] = React.useState(user?.name || '');
  const [editedEmail, setEditedEmail] = React.useState(user?.email || '');
  const [editedPassword, setEditedPassword] = React.useState('');
  const [label, setLabel] = React.useState('Mon Profil');

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
    setLabel('Modification du profil');
  };

  const handleEditProfile = async (editedUser) => {
    try {
      const { data } = await editUserMutation({
        variables: { id: user.id, ...editedUser },
      });

      // Traitez la réponse ou mettez à jour l'état local
      dispatch(editSuccess(data.editUser));

      // Rafraîchir les données de l'utilisateur
      refetch();
    } catch (error) {
      console.error('Error updating user:', error.message);
    }
  };

  const handleSaveChanges = () => {
    const editedUser = {
      name: editedName,
      email: editedEmail,
      password: editedPassword,
    };

    // Appel de la fonction onEditProfile avec les nouvelles données
    handleEditProfile(editedUser);
    setIsEditing(false);
    setLabel('Mon Profil');
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setLabel('Mon Profil');
  };

  return (
    <PopupContainer id="profile-popup" onClick={(e) => e.stopPropagation()}>
      <h3 style={{ textAlign: "center", borderBottom: '1px solid #ccc', paddingBottom: 10, marginTop: 0, marginBottom: 0 }}>{label}</h3>
      <ProfileInfo>
        {isEditing ? (
          <InputContainer>
            <label>Nom:</label>
            <input
              type="text"
              value={editedName}
              onChange={(e) => setEditedName(e.target.value)}
            />
            <label>Email:</label>
            <input
              type="text"
              value={editedEmail}
              onChange={(e) => setEditedEmail(e.target.value)}
            />
            <label>Mot de passe:</label>
            <input
              type="text"
              value={editedPassword}
              onChange={(e) => setEditedPassword(e.target.value)}
            />
            <ButtonContainer>
              <CancelButton onClick={handleCancelEdit}>Annuler</CancelButton>
              <SaveButton onClick={handleSaveChanges}>Enregistrer</SaveButton>
            </ButtonContainer>
          </InputContainer>
        ) : (
          <>
            <UserName>{user?.name}</UserName>
            <UserEmail>{user?.email}</UserEmail>
            <EditProfileButton onClick={handleEditToggle}>
              Modifier le profil
            </EditProfileButton>
          </>
        )}
      </ProfileInfo>
    </PopupContainer>
  );
};

export default Profile;
