import { gql } from '@apollo/client';

export const GET_USER_FURNITURES = gql`
  query getUserFurnitures {
    getUserFurnitures {
      id
      nom
      description
      prix
      taille
      image
      meubleType {
        id
        nom # Si vous souhaitez récupérer des détails sur le type de meuble
      }
      disponible
      # Autres champs du meuble que vous souhaitez récupérer
    }
  }
`;

export default GET_USER_FURNITURES;
