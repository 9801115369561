import React, {useEffect, useRef} from 'react';
import Lottie from 'lottie-react';
import animationData from './lotties/OurHistory.json';

const OurHistory = ({isHovered}) => {
    let animationRef = useRef(); // Référence pour contrôler l'animation
    const defaultOptions = {
        loop: !!isHovered,
        autoplay: false, // L'autoplay est désactivé
        speed: isHovered ? 1 : 0,
        animationData: animationData
    };

    useEffect(() => {
        animationRef?.current?.pause()
        if (isHovered) {
            animationRef?.current?.play()
        }
    }, [isHovered]);

    return (
        <div style={styles.container}>
            <Lottie
                lottieRef={animationRef}
                options={defaultOptions}
                animationData={animationData}
                height={'auto'}
                width={'100%'}
            />
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '150px',
        margin: '0 auto -20px auto'
    },
};

export default OurHistory;
