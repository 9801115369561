import React, {useEffect, useState} from "react";
import {useMutation, useQuery} from "@apollo/client";
import CREATE_FURNITURE from "./Redux/API/createFurniture";
import GET_MEUBLES_TYPES from "./Redux/API/getMeublesTypes";
import View from "../Common/View";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import ColorsEnum from "../Utils/Enums/Colors";
import MaterialsEnum from "../Utils/Enums/Materials";
import {useDropzone} from 'react-dropzone';
import {FaTimesCircle} from "react-icons/fa";
import {palettes} from "../Common/Colors";

const FormContainer = styled.div`
  padding: 20px;
  background: #f7f7f7;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Container = styled.div`
  width: 75%;
  margin: 0 auto;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;

  label {
    display: block;
    font-weight: bold;
  }

  input, textarea {
    width: 95%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
`;

const FormGroupRow = styled.div`
  margin-bottom: 20px;
  margin-right: 25px;
  display: flex;
  justify-content: space-between;

  label {
    display: block;
    font-weight: bold;
  }

  input, textarea {
    width: 95%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
`;

const SubmitButton = styled.button`
  background: ${palettes.primary.dark};
  color: white;
  font-weight: bold;
  font-size: 18px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #1f4d74;
  }
`;

const CharacterCountContainer = styled.div`
  color: #555;
  font-size: 14px;
`;

const SelectField = styled.select`
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: calc(95% + 20px);
  background: #fff; /* Couleur de fond */
  color: #333; /* Couleur du texte */
  transition: border-color 0.2s;

  &:focus {
    outline: none;
    border-color: ${palettes.primary.dark}; /* Couleur de la bordure lorsqu'il est sélectionné */
  }
`;

const SelectOption = styled.option`
  /* Style des options du sélecteur (si nécessaire) */
`;

const ImagePreview = styled.div`
  margin-top: 10px;
`;

const ImageThumbnail = styled.img`
  max-width: 150px; // Ajustez la largeur maximale comme vous le souhaitez
  max-height: 150px; // Ajustez la hauteur maximale comme vous le souhaitez
  margin-right: 10px;
`;

const DropzoneContainer = styled.div`
  border: 2px dashed ${palettes.primary.dark};
  margin-top: 10px;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  z-index: 1;
`;

const DropzoneText = styled.div`
  font-size: 18px;
  color: ${palettes.primary.dark};
  margin-bottom: 10px;
`;

const RemoveIcon = styled.div`
  position: absolute;
  top: -10px;
  right: 0;
  cursor: pointer;
  color: red;
  padding: 4px;
  z-index: 5;
`;

const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  z-index: 5;
`;

const FormRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

    button {
        width: 275px;
        margin-left: 10px;
        background-color: ${palettes.primary.dark};
        color: white;
        font-weight: bold;
        font-size: 14px;
        padding: 12px 16px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
            background: #1f4d74;
        }
    }
`;

const CreateFurnitureView = () => {
    const navigate = useNavigate();
    const userIsLoggedIn = useSelector((state) => state.auth.user);
    const {loading: typesLoading, data: typesData} = useQuery(GET_MEUBLES_TYPES);
    const { loading, error, data } = useQuery(GET_MEUBLES_TYPES);
    const products = data?.getFurnitures || [];
    const isNameAlreadyUsed = (nameToCheck) => {
        return products.some(furniture => furniture.nom === nameToCheck);
    };
    useEffect(() => {
        if (!userIsLoggedIn) {
            navigate('/products');
        }
    }, []);

    const [furnitureData, setFurnitureData] = useState({
        utilisateur: "",
        email: "",
        nom: "",
        description: "",
        prix: 0,
        taille: "",
        image: null, // Utilisez null comme valeur initiale pour le champ d'entrée de fichier
        categorie_id: "",
        images: [], // Utilisez un tableau vide pour la liste d'images
        couleur: "",   // Champ "couleur"
        materiau: "",  // Champ "materiau"
        etat: "",      // Champ "etat"
    });

    const [remainingCharacters, setRemainingCharacters] = useState(4000);
    const [createFurniture] = useMutation(CREATE_FURNITURE);
    const defaultColors = Object.values(ColorsEnum);
    const defaultStates = ['Neuf', 'Bon', 'Passable'];

    const handleInputChange = (e) => {
        let {name, value} = e.target;
        if (name === 'prix') {
            value = parseFloat(value);
        }
        setFurnitureData({...furnitureData, [name]: value});

        if (name === 'description') {
            // Mettre à jour le nombre de caractères restants
            const charactersUsed = value.length;
            const charactersRemaining = 4000 - charactersUsed;
            setRemainingCharacters(charactersRemaining);
        }
    };

    const handleMeubleTypeChange = (e) => {
        const selectedTypeId = parseInt(e.target.value);
        setFurnitureData({...furnitureData, categorie_id: selectedTypeId});
    };

    const onDropMainImage = (acceptedFiles) => {
        setFurnitureData({...furnitureData, image: acceptedFiles[0]});
    };

    const onDropAdditionalImages = (acceptedFiles) => {
        setFurnitureData({ ...furnitureData, images: [...furnitureData.images, ...acceptedFiles] });
    };

    const {getRootProps: getMainImageRootProps, getInputProps: getMainImageInputProps} = useDropzone({
        onDrop: onDropMainImage,
        accept: 'image/*',
    });

    const {getRootProps: getAdditionalImagesRootProps, getInputProps: getAdditionalImagesInputProps} = useDropzone({
        onDrop: onDropAdditionalImages,
        accept: 'image/*',
        multiple: true,
    });

    const handleRemoveImage = (index) => {
        const updatedImages = [...furnitureData.images];
        updatedImages.splice(index, 1);
        setFurnitureData({...furnitureData, images: updatedImages});
    };

    const handleRemoveImageClick = (e, index) => {
        e.preventDefault(); // Empêche le comportement par défaut du clic (sélecteur de fichier)
        e.stopPropagation(); // Empêche la propagation de l'événement au conteneur parent
        handleRemoveImage(index);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const variables = {
            ...furnitureData,
            nom: furnitureData.nom.trim(),
            description: furnitureData.description.trim(),
            email: furnitureData.email.trim(),
            utilisateur: furnitureData.utilisateur.trim()
        };

        // Vérifiez si furnitureData.image est défini
        if (furnitureData.image) {
            variables.image = furnitureData.image;
        }

        createFurniture({
            variables: {...variables},
        })
            .then((result) => {
                setFurnitureData({
                    utilisateur: "",
                    email: "",
                    nom: "",
                    description: "",
                    prix: 0,
                    taille: "",
                    image: null,
                    categorie_id: 1,
                    images: []
                });
                navigate(`/products/${result.data.createFurniture.id}`);
            })
            .catch((error) => {
                console.error("Erreur lors de la création du meuble :", error);
            });
    };

    const fetchRandomName = () => {
        // Génération d'un nombre aléatoire entre 0 et 25465
        const randomValue = Math.floor(Math.random() * 25466);

        // Construction de l'URL avec le paramètre rand
        const apiUrl = `https://opendata.paris.fr/api/explore/v2.1/catalog/datasets/liste_des_prenoms/records?select=prenoms&order_by=random(${randomValue})&limit=1`;

        fetch(apiUrl)
          .then(response => response.json())
          .then(data => {
              const randomName = data.results[0]?.prenoms;

              // Vérifier si le prénom est déjà utilisé par un autre meuble
              if (randomName && !isNameAlreadyUsed(randomName)) {
                  setFurnitureData({ ...furnitureData, nom: randomName });
              } else {
                  // Si le prénom est déjà utilisé ou non trouvé, réessayer
                  fetchRandomName();
              }
          })
          .catch(error => console.error('Erreur lors de la récupération des prénoms :', error));
    };

    return (
        <View>
            <Container>
                <h1>Ajouter un nouveau produit</h1>
                <FormContainer>
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                        <FormGroup>
                            <label>Nom du produit</label>
                            <FormRow>
                                <input
                                  type="text"
                                  name="nom"
                                  value={furnitureData.nom}
                                  onChange={handleInputChange}
                                  required
                                />
                                <button type="button" onClick={fetchRandomName}>
                                    Générer un prénom aléatoire
                                </button>
                            </FormRow>
                        </FormGroup>
                        <FormGroup>
                            <label>Description:</label>
                            <textarea
                              name="description"
                                rows={10}
                                value={furnitureData.description}
                                onChange={handleInputChange}
                                maxLength={4000}
                                required
                            />
                            <CharacterCountContainer>
                                {remainingCharacters} caractères restants
                            </CharacterCountContainer>
                        </FormGroup>
                        <FormGroupRow>
                            <div style={{width: "45%"}}>
                                <label>Prix</label>
                                <input
                                    style={{margin: '5px 0'}}
                                    type="number"
                                    name="prix"
                                    value={furnitureData.prix}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div style={{width: "45%"}}>
                                <label>Type de meuble</label>
                                <SelectField
                                    name="meubleTypeId"
                                    value={furnitureData.categorie_id}
                                    onChange={handleMeubleTypeChange}
                                    required
                                >
                                    <SelectOption value="" disabled>Sélectionnez un type de meuble</SelectOption>
                                    {typesLoading ? (
                                        <SelectOption>Loading...</SelectOption>
                                    ) : (
                                        typesData?.getMeublesTypes?.map((type) => (
                                            <SelectOption key={type.id} value={type.id}>
                                                {type.nom}
                                            </SelectOption>
                                        ))
                                    )}
                                </SelectField>
                            </div>
                        </FormGroupRow>
                        <FormGroupRow>
                            <div style={{width: "45%"}}>
                                <label>Taille</label>
                                <input
                                    style={{margin: '5px 0'}}
                                    type="text"
                                    name="taille"
                                    value={furnitureData.taille}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div style={{width: "45%"}}>
                                <label>Couleur:</label>
                                <SelectField
                                    name="couleur"
                                    value={furnitureData.couleur}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <SelectOption value="" disabled>Sélectionnez une couleur</SelectOption>
                                    {typesLoading ? (
                                        <SelectOption>Loading...</SelectOption>
                                    ) : (
                                        defaultColors.map((color, index) => (
                                            <SelectOption key={index} value={color}>
                                                {color}
                                            </SelectOption>
                                        ))
                                    )}
                                </SelectField>
                            </div>
                        </FormGroupRow>
                        <FormGroupRow>
                            <div style={{width: "45%"}}>
                                <label>Matériau:</label>
                                <SelectField
                                    name="materiau"
                                    value={furnitureData.materiau}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <SelectOption value="" disabled>Sélectionnez un matériau</SelectOption>
                                    {typesLoading ? (
                                        <SelectOption>Loading...</SelectOption>
                                    ) : (
                                        Object.values(MaterialsEnum).map((material, index) => (
                                            <SelectOption key={index} value={material}>
                                                {material}
                                            </SelectOption>
                                        ))
                                    )}
                                </SelectField>
                            </div>
                            <div style={{width: "45%"}}>
                                <label>État:</label>
                                <SelectField
                                    name="etat"
                                    value={furnitureData.etat}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <SelectOption value="" disabled>Sélectionnez un état</SelectOption>
                                    {typesLoading ? (
                                        <SelectOption>Loading...</SelectOption>
                                    ) : (
                                        defaultStates.map((state, index) => (
                                            <SelectOption key={index} value={state}>
                                                {state}
                                            </SelectOption>
                                        ))
                                    )}
                                </SelectField>
                            </div>
                        </FormGroupRow>
                        <FormGroup>
                            <label>Image principale:</label>
                            <DropzoneContainer {...getMainImageRootProps()}>
                                <input {...getMainImageInputProps()} />
                                <DropzoneText>Faites glisser et déposez l'image principale ici, ou cliquez pour la
                                    sélectionner.</DropzoneText>
                                {furnitureData.image && (
                                    <ImagePreview>
                                        <ImageThumbnail src={URL.createObjectURL(furnitureData.image)}
                                                        alt="Image principale"/>
                                    </ImagePreview>
                                )}
                            </DropzoneContainer>
                        </FormGroup>
                        <FormGroup>
                            <label>Images supplémentaires:</label>
                            <DropzoneContainer {...getAdditionalImagesRootProps()}>
                                <input {...getAdditionalImagesInputProps()} />
                                <DropzoneText>Faites glisser et déposez des images ici, ou cliquez pour sélectionner des
                                    fichiers.</DropzoneText>
                                {furnitureData.images.length > 0 && (
                                    <ImagePreview>
                                        {furnitureData.images.map((image, index) => (
                                            <ImageContainer key={index}>
                                                <RemoveIcon onClick={(e) => handleRemoveImageClick(e, index)}>
                                                    <FaTimesCircle/>
                                                </RemoveIcon>
                                                <ImageThumbnail src={URL.createObjectURL(image)}
                                                                alt={`Image ${index + 1}`}/>
                                            </ImageContainer>
                                        ))}
                                    </ImagePreview>
                                )}
                            </DropzoneContainer>
                        </FormGroup>
                        <FormGroup>
                            <label>Adresse mail du propriétaire</label>
                            <input
                                style={{margin: '5px 0'}}
                                type="email"
                                name="email"
                                value={furnitureData.email}
                                onChange={handleInputChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Prénom du propriétaire</label>
                            <input
                                style={{margin: '5px 0'}}
                                type="texte"
                                name="utilisateur"
                                value={furnitureData.utilisateur}
                                onChange={handleInputChange}
                                required
                            />
                        </FormGroup>
                        <SubmitButton type="submit">Créer le produit</SubmitButton>
                    </form>
                </FormContainer>
            </Container>
        </View>
    );
};

export default CreateFurnitureView;
