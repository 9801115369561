// Order.js

import React, { useEffect } from 'react';
import styled from 'styled-components';
import View from '../Common/View';
import {useMutation, useQuery} from '@apollo/client';
import GET_ORDERS from "./Redux/API/getOrders";
import {ACCEPT_COMMAND} from "./Redux/API/accpetCommand";

const Header = styled.header`
    text-align: center;
    margin-bottom: 20px;
    padding: 40px 0;
    background-color: #ffffff;
`;

const HeaderTitle = styled.h1`
    font-size: 2.5em;
    color: #333;
`;

const OrderSection = styled.section`
    padding: 20px;
`;

const OrderList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;

const OrderItemContainer = styled.li`
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const OrderItem = styled.li`
    padding: 20px;
    display: flex;
    justify-content: space-between;
`;

const Section = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-self: ${({ isButton }) => (isButton ? 'center' : 'initial')};

    button {
        background-color: #4CAF50;
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 16px;
        margin-top: 10px;
    }
`;

const Button = styled.button`
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
`;

const FurnitureList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
`;

const FurnitureItem = styled.div`
  display: flex;
  align-items: center;
  margin: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  padding: 8px;

  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 8px;
  }

  div {
    flex: 1;
    strong {
      font-size: 1.1em;
      color: #333;
    }
  }
`;

const Order = () => {
  const { loading, data, error } = useQuery(GET_ORDERS);
  const [acceptOrder, { loading: mutationLoading, error: mutationError }] = useMutation(ACCEPT_COMMAND, {
    onCompleted: (data) => {
      console.log('Commande acceptée avec succès:', data);
      // Vous pouvez également effectuer des actions supplémentaires après l'acceptation de la commande
    },
    onError: (error) => {
      console.error('Erreur lors de l\'acceptation de la commande:', error);
      // Gérez les erreurs si nécessaire
    },
  });

  useEffect(() => {
    if (data) {
      console.log('Commandes :', data.getOrders);
    }
    if (error) {
      console.error('Erreur lors de la récupération des commandes :', error);
    }
  }, [data, error]);

  if (loading) return <p>Chargement en cours...</p>;
  if (error) return <p>Erreur : {error.message}</p>;

  // Fonction pour calculer le prix total des meubles
  const calculateTotalFurniturePrice = (furnitureList) => {
    return furnitureList.reduce((total, furniture) => total + furniture.prix, 0);
  };

  const handleAcceptCommand = (commandId) => {
    // Appelez la fonction mutate pour déclencher la mutation
    acceptOrder({
      variables: {
        orderId: commandId,
      },
    });
  };


  return (
    <View>
      <Header>
        <HeaderTitle>Bons de Commandes</HeaderTitle>
      </Header>
      <OrderSection>
        <OrderList>
          {data.getOrders.map((commande) => (
            <OrderItemContainer>
              <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <h2 style={{margin: 0}}>Bon de commande {commande.id}</h2>
                {commande.accepted ? (
                  <p style={{color: "#4CAF50", fontWeight: "bolder", fontSize: 20}}>Commande acceptée</p>
                ) : (
                  <Button onClick={() => handleAcceptCommand(commande.id)}>Accepter la commande</Button>
                )}
              </div>
              <OrderItem key={commande.id}>
                <Section>
                  <h3>Informations de l'acheteur</h3>
                  <p>Client : {commande.name} {commande.firstname}</p>
                  <p>Email : {commande.email}</p>
                  <p>Téléphone : {commande.phone}</p>
                  {commande.address && <p>Adresse : {commande.address}</p>}
                  {commande.city && <p>Ville : {commande.city}</p>}
                </Section>
                <Section>
                  <h3>Informations de l'achat</h3>
                  <p>Montant total : {calculateTotalFurniturePrice(commande.furniture).toFixed(2)} €</p>
                  <p>Date : {commande.date}</p>
                  <p>Livraison : {commande.isDeliveryChecked ? 'Oui' : 'Non'}</p>
                </Section>
                <Section>
                  <h3>Informations des meubles</h3>
                  <FurnitureList>
                    {commande.furniture.map(meuble => (
                      <FurnitureItem key={meuble.id}>
                        <img src={meuble.image} alt={meuble.nom}/>
                        <div>
                          <strong>{meuble.nom}</strong>
                        </div>
                      </FurnitureItem>
                    ))}
                  </FurnitureList>
                </Section>
              </OrderItem>
            </OrderItemContainer>
          ))}
        </OrderList>
      </OrderSection>
    </View>
  );
};

export default Order;
