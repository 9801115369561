import React from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import HowItWorks from './HowItWorks';
import ProductPage from './ProductPage';
import Contact from './Contact';
import About from './About';
import ProductDetails from "./ProductPage/ProductDetails";
import {ApolloProvider} from "@apollo/client";
import client from './Client/client';
import CreateFurniture from "./CreateFurniture";
import EditFurniture from "./EditFurniture";
import Pay from "./ProductPage/Pay";
import UserProducts from "./UserProducts";
import Order from "./Order";

const App = () => {
    return (
        <ApolloProvider client={client}>
            <Router>
                <Routes>
                    <Route path="/howitworks" element={<HowItWorks />} />
                    <Route path="/products" element={<ProductPage />} />
                    <Route path="/yourproducts" element={<UserProducts />} />
                    <Route path="/yourproducts/:id" element={<ProductDetails />}/>
                    <Route path="/contact/:id" element={<Contact />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/products/:id" element={<ProductDetails />}/>
                    <Route path="/add" element={<CreateFurniture />}/>
                    <Route path="/order" element={<Order />}/>
                    <Route path="/edit/:id" element={<EditFurniture />}/>
                    <Route path="/payment" element={<Pay />}/>
                    <Route path="/" element={<Navigate to="/products" />} />
                </Routes>
            </Router>
        </ApolloProvider>
    );
};

export default App;
