import styled from "styled-components";
import {palettes} from "../../Common/Colors";


export const ButtonDelete = styled.button`
  background-color: white;
  color: ${palettes.primary.light};
  cursor: pointer;
  padding: 10px 20px;
  width: 50%;
  margin: 5px 5px -5px -50px; /* Ajoute une marge à gauche pour espacer les boutons */
  border: 1px solid ${palettes.primary.light};
  transition: background-color 0.2s;

  &:hover {
    background-color: #ccc;
    color: white;
  }
`;

export const ButtonSave = styled.button`
  background-color: ${palettes.primary.light};
  color: white;
  cursor: pointer;
  padding: 10px 20px;
  width: 50%;
  margin: 5px -50px -5px 5px; /* Ajoute une marge à droite pour espacer les boutons */
  border: 1px solid ${palettes.primary.light};
  transition: background-color 0.2s;

  &:hover {
    background-color: ${palettes.primary.dark};
  }
`;

export const Check = styled.span`
  display: flex; /* Ajoutez une règle pour aligner le contenu verticalement au centre */
  align-items: center; /* Ajoutez une règle pour aligner le contenu verticalement au centre */
  margin-left: auto;
  margin-top: 2px;
  color: ${palettes.primary.light};
`;

export const SortOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  padding-right: 10px;
  margin-right: -10px;
  max-height: 200px; /* Hauteur maximale pour la zone d'options */
  overflow-y: auto; /* Barre de défilement verticale en cas de dépassement */
  overflow-x: hidden; /* Barre de défilement verticale en cas de dépassement */
`;

export const SortOption = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 5px;
  padding-top: 10px;
  padding-bottom: 5px;
  color: ${palettes.primary.light};
  width: 100%;
  border-top: 1px solid #ccc;

  &:first-child {
    border-top: none;
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;
