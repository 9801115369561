import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useMutation, useQuery} from "@apollo/client";
import GET_FURNITURE from "../ProductPage/Redux/API/getFurniture";
import {useParams} from "react-router-dom";
import {palettes} from "../Common/Colors";
import SEND_CONTACT from "./Redux/API/sendContact";

const ContactForm = styled.form`
  padding: 20px;
  background-color: #fafafa;
  border-radius: 10px;
  box-shadow: 7px 7px 7px rgba(51, 153, 255, 0.15);
  margin-left: 5%;
  width: 35%;

    @media (max-width: 768px) {
        width: auto;
        margin: 15px 5px;
    }
`;

const ContactFormField = styled.div`
  margin-bottom: 20px;
`;

const ContactFormLabel = styled.label`
  display: block;
  font-size: 1.2em;
  margin-bottom: 5px;
  font-weight: bold;
`;

const ContactFormInput = styled.input`
  width: calc(100% - 30px);
  padding: 15px;
  font-size: 1.2em;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 5px;
`;

const ContactFormTextarea = styled.textarea`
  width: calc(100% - 30px);
  padding: 15px;
  font-size: 1.2em;
  height: 150px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 5px;
`;

const ContactFormButton = styled.button`
  padding: 15px 30px;
  font-size: 1.2em;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #555;
  }
`;

const AppointmentTypeSelect = styled.select`
  width: 100%;
  padding: 15px;
  font-size: 1.2em;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 5px;
`;

const ContactCard = () => {
    const {id} = useParams();
    const {loading, error, data} = useQuery(GET_FURNITURE, {
        variables: {id: parseInt(id)}, // Passez l'ID comme variable
    });
    const [sendContact] = useMutation(SEND_CONTACT);
    const product = data?.getFurniture;
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [appointmentType, setAppointmentType] = useState('');
    const [message, setMessage] = useState(`Bonjour,

Je souhaite prendre rendez-vous pour [Choisir un type de rendez-vous]. Pouvez-vous me fournir plus d'informations ?

Cordialement,
[Votre Nom]`);

    const [buttonColor, setButtonColor] = useState('#333'); // Couleur de départ du bouton

    const handleInputChange = () => {
        // Vérifiez si tous les champs obligatoires sont remplis
        if (
            firstName.trim() !== '' &&
            lastName.trim() !== '' &&
            email.trim() !== '' &&
            appointmentType !== '' &&
            appointmentType !== '[Choisir un type de rendez-vous]'
        ) {
            // Tous les champs sont remplis, mettez à jour la couleur du bouton
            setButtonColor(palettes.primary.dark);
        } else {
            // Au moins un champ est vide, réinitialisez la couleur du bouton
            setButtonColor('#333');
        }
    };

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    const handleAppointmentTypeChange = async (e) => {
        await setAppointmentType(e.target.value);
        await setMessage(`Bonjour,

Je souhaite prendre rendez-vous pour ${e.target.value}. Pouvez-vous me fournir plus d'informations ?

Cordialement,
[Votre Nom]`);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Vérifiez une dernière fois si tous les champs sont correctement remplis
        if (
            firstName.trim() === '' ||
            lastName.trim() === '' ||
            email.trim() === '' ||
            appointmentType === '' ||
            appointmentType === '[Choisir un type de rendez-vous]'
        ) {
            alert('Veuillez remplir tous les champs obligatoires.');
            return
        }
        const name = lastName + ' ' + firstName
        sendContact({
            variables: {
                name,
                email,
                message
            }
        })
          .then((result) => {
              if (result) {
                  alert("Votre message a bien été envoyé.")
              } else {
                  alert("Il y a eu un erreur lors de l'envoie du message. Merci de nous contacter par mail : jungledumonde@gmail.com.")
              }
          })
          .catch((error) => {
              console.error("Erreur lors de l'envoi de message :", error);
          })
    };

    useEffect(() => {
        if (product) {
            const value = 'un achat de meuble'
            setAppointmentType(value);
            setMessage(`Bonjour,

Je souhaite prendre rendez-vous pour avoir des informations sur un produit.
Produit : http://localhost:3000/products/${id}


Cordialement,
[Votre Nom]`);
        }
        handleInputChange();
    }, [firstName, lastName, email, appointmentType, message])

    return (
        <ContactForm onSubmit={handleSubmit}>
            <ContactFormField>
                <ContactFormLabel htmlFor="firstName">Prénom</ContactFormLabel>
                <ContactFormInput
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                />
            </ContactFormField>
            <ContactFormField>
                <ContactFormLabel htmlFor="lastName">Nom</ContactFormLabel>
                <ContactFormInput
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                />
            </ContactFormField>
            <ContactFormField>
                <ContactFormLabel htmlFor="email">Email</ContactFormLabel>
                <ContactFormInput
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
            </ContactFormField>
            <ContactFormField>
                <ContactFormLabel htmlFor="appointmentType">Type de rendez-vous</ContactFormLabel>
                <AppointmentTypeSelect
                    id="appointmentType"
                    name="appointmentType"
                    onChange={handleAppointmentTypeChange}
                    value={appointmentType}
                    required
                >
                    <option value="[Choisir un type de rendez-vous]">Sélectionner un type de rendez-vous</option>
                    <option value="une récupération de meubles">Récupération de meubles</option>
                    <option value="une visite d'appartement">Visite d'appartement</option>
                    <option value="un achat de meuble">Achat de meuble</option>
                </AppointmentTypeSelect>
            </ContactFormField>
            <ContactFormField>
                <ContactFormLabel htmlFor="message">Message</ContactFormLabel>
                <ContactFormTextarea
                    id="message"
                    name="message"
                    value={message}
                    onChange={handleMessageChange}
                    required
                />
            </ContactFormField>
            <ContactFormButton type="submit" style={{ backgroundColor: buttonColor }}>
                Envoyer
            </ContactFormButton>
        </ContactForm>
    );
};

export default ContactCard;
