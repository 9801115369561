// reducers/authReducer.js

import {EDIT_SUCCESS, GET_USER_SUCCESS, LOGIN_SUCCESS, LOGOUT, REGISTER_SUCCESS} from "../Actions/auhtActions";

const initialState = {
    user: null,
    token: null,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                user: action.user,
                token: action.user.token,
            };
        case REGISTER_SUCCESS:
            return {
                ...state,
                user: action.payload,
            };
        case EDIT_SUCCESS:
            return {
                ...state,
                user: action.payload,
            };
        case GET_USER_SUCCESS:
            return {
                ...state,
                user: action.user,
                token: action.user.token,
            };
        case LOGOUT:
            return {
                ...state,
                user: null, // Supprimez l'utilisateur lors de la déconnexion.
            };
        default:
            return state;
    }
};

export default authReducer;
