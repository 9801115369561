import { gql } from '@apollo/client';

export const GET_FURNITURES = gql`
  query getFurnitures(
    $meuble_type_id: [Int]
    $minPrix: Float
    $maxPrix: Float
    $orderBy: String
    $couleur: [String]
    $etat: String
    $nom: String
  ) {
    getFurnitures(
      meuble_type_id: $meuble_type_id
      minPrix: $minPrix
      maxPrix: $maxPrix
      orderBy: $orderBy
      couleur: $couleur
      etat: $etat
      nom: $nom
    ) {
      id
      nom
      description
      prix
      taille
      image
      meubleType {
        id
        nom # Si vous souhaitez récupérer des détails sur le type de meuble
      }
      disponible
      # Autres champs du meuble que vous souhaitez récupérer
    }
  }
`;

export default GET_FURNITURES;
