// reducers/productReducer.js

import {
    ADD_TO_CART,
    CHECK_PROMO_CODE,
    CREATE_PAYMENT_INTENT,
    GET_FURNITURE_SUCCESS,
    GET_FURNITURES_SUCCESS,
    PURCHASE
} from "../Actions/productActions";
import {CLOSE_CART} from "../../../Common/Redux/Actions/auhtActions";

const initialState = {
    products: [],
    product: null,
    cart: { cartItems: [], open: false, totalAmount: 0 },
    purchased: { purchasedItems: [], totalAmount: 0 }
};

const getProductReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_FURNITURES_SUCCESS:
            // Ajoutez les nouveaux meubles récupérés à la liste des produits
            const products = action.products;
            return {
                ...state,
                products,
            };
        case GET_FURNITURE_SUCCESS:
            // Ajoutez le meuble récupéré
            const product = action.product;
            return {
                ...state,
                product,
            };
        case CLOSE_CART:
            const open = action.payload;
            return {
                ...state,
                cart: {
                    ...state.cart,
                    open
                },
            };
        case ADD_TO_CART:
            const newItem = action.payload;
            const existingItem = state.cart && state.cart.cartItems && state.cart.cartItems.length > 0
              ? state.cart.cartItems.find(item => item.id === newItem.id)
              : false;

            if (existingItem) {
                // If the item already exists in the cart, increment its quantity
                return {
                    ...state,
                    cart: {
                        ...state.cart,
                        cartItems: state.cart.cartItems.map(item =>
                          item.id === newItem.id
                            ? { ...item, quantity: item.quantity }
                            : item
                        ),
                        open: true,
                        totalAmount: 0
                    },
                    purchased: { purchasedItems: [], totalAmount: 0 }
                };
            } else {
                // If the item is not in the cart, add it with quantity 1
                return {
                    ...state,
                    cart: {
                        ...state.cart,
                        cartItems: [...state.cart.cartItems, { ...newItem, quantity: 1 }],
                        open: true,
                        totalAmount: 0
                    },
                    purchased: { purchasedItems: [], totalAmount: 0 }
                };
            }
        case 'REMOVE_FROM_CART':
            const updatedCartItems = state.cart.cartItems.filter(item => item.id !== action.payload);
            return {
                ...state,
                cart: {
                    ...state.cart,
                    cartItems: updatedCartItems,
                    totalAmount: 0
                },
            };
        case PURCHASE:
            const purchasedItems = state.cart.cartItems
            return {
                ...state,
                cart: {
                    ...state.cart,
                    cartItems: [],
                    totalAmount: 0
                },
                purchased: { purchasedItems: [...purchasedItems], totalAmount: 0 }
            };
        case CREATE_PAYMENT_INTENT:
            return {
                ...state,
            };
        case CHECK_PROMO_CODE:
            console.log(action.payload)
            return {
                ...state,
                cart: { cartItems: state.cart.cartItems, totalAmount: action.payload },
                purchased: { purchasedItems: state.purchased.purchasedItems, totalAmount: action.payload }
            };
        default:
            return state;
    }
};

export default getProductReducer;
