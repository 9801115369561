import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import styled from "styled-components";
import moment from "moment";
import {palettes} from "../../Common/Colors";

const FormContainer = styled.div`
  width: 100%;
  padding: 0 20px 20px 20px;
  margin: 0;
`;

const FormGroup = styled.div`
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  justify-content: space-between;
`;

const Form = styled.div`
  width: 45%;
`;

const CustomDatePickerInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
`;

const Label = styled.label`
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
`;

const Input = styled.input`
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
`;

const Button = styled.button`
  background-color: ${palettes.primary.dark};
  color: white;
  border: none;
  border-radius: 5px;
  padding: 15px 30px;
  font-size: 1.2em;
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const AppointmentForm = ({ onNextStep }) => {
    const [formData, setFormData] = useState({
        selectedDate: null,
        selectedTime: null,
        name: "",
        firstname: "",
        email: "",
        phoneNumber: "",
    });
    const [dateSelected, setDateSelected] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    let excluded = [
        {
            "time": "20:00",
            "date": "05/11/2023",
        },
        {
            "time": "18:00",
            "date": "05/11/2023",
        },
        {
            "time": "18:00",
            "date": "06/11/2023",
        },
    ];

    useEffect(() => {
        // Vérifiez si tous les champs sont remplis
        const isFormFilled =
            formData.selectedDate &&
            formData.selectedTime &&
            formData.name &&
            formData.firstname &&
            formData.email &&
            formData.phoneNumber;

        setIsFormValid(isFormFilled);
    }, [formData]);

    const handleDateChange = (date) => {
        setFormData({
            ...formData,
            selectedDate: date,
        });
        setDateSelected(!!date);
    };

    const handleTimeChange = (time) => {
        setFormData({
            ...formData,
            selectedTime: time,
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleNextStep = () => {
        // Vous pouvez envoyer les données à l'étape suivante ici
        onNextStep(formData);
    };

    const isDateExcluded = (selectedDate, exclude) => {
        return (
            moment(selectedDate).format('DD/MM/YYYY') ===
            moment(exclude.date, 'DD/MM/YYYY').format('DD/MM/YYYY') &&
            moment(selectedDate).isSame(
                moment(exclude.date, 'DD/MM/YYYY'),
                'day'
            )
        )
    };

    return (
        <FormContainer>
            <h2>Date pour récupérer le produit</h2>
            <FormGroup>
                <Form>
                    <h3>Choisissez le jour</h3>
                    <DatePicker
                        selected={formData.selectedDate}
                        onChange={handleDateChange}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="eg. 27/12/2020"
                        minDate={new Date()}
                        required
                        customInput={
                            <CustomDatePickerInput
                                name="time"
                                onKeyPress={(e) => {
                                    const allowedCharacters = /[0-9/]/;
                                    if (!allowedCharacters.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        }
                    />
                </Form>

                {dateSelected && (
                    <Form>
                        <h3>Choisissez l'heure</h3>
                        <DatePicker
                            selected={formData.selectedTime}
                            onChange={handleTimeChange}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={30}
                            minTime={setHours(setMinutes(new Date(), 0), 10)}
                            maxTime={setHours(setMinutes(new Date(), 30), 20)}
                            excludeTimes={
                                formData.selectedDate &&
                                excluded.find((exclude) =>
                                    isDateExcluded(formData.selectedDate, exclude)
                                )
                                    ? excluded
                                        .filter((exclude) =>
                                            isDateExcluded(formData.selectedDate, exclude)
                                        )
                                        .map((exclude) =>
                                            moment(exclude.time, 'HH:mm').toDate()
                                        )
                                    : []
                            }
                            timeCaption="Heure"
                            timeFormat="HH:mm"
                            dateFormat="HH:mm"
                            customInput={
                                <CustomDatePickerInput
                                    name="time"
                                    required
                                    onKeyPress={(e) => {
                                        const allowedCharacters = /[0-9:]/;
                                        if (!allowedCharacters.test(e.key)) {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                            }
                        />
                    </Form>
                )}
            </FormGroup>

            <h2>Informations personnelles</h2>
            <FormGroup>
                <Form>
                    <Label>Nom</Label>
                    <Input
                        type="text"
                        name="name"
                        value={formData.name}
                        placeholder="Nom"
                        onChange={handleInputChange}
                        required
                    />
                </Form>
                <Form>
                    <Label>Prénom</Label>
                    <Input
                        type="text"
                        name="firstname"
                        value={formData.firstname}
                        placeholder="Prénom"
                        onChange={handleInputChange}
                        required
                    />
                </Form>
            </FormGroup>
            <FormGroup>
                <Form>
                    <Label>E-mail</Label>
                    <Input
                        type="email"
                        name="email"
                        value={formData.email}
                        placeholder="E-mail"
                        onChange={handleInputChange}
                        required
                    />
                </Form>
                <Form>
                    <Label>Numéro de téléphone</Label>
                    <Input
                        type="text"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        placeholder="Numéro de téléphone"
                        onChange={handleInputChange}
                        required
                    />
                </Form>
            </FormGroup>

            <Button onClick={handleNextStep} disabled={!isFormValid}>
                Étape suivante
            </Button>
        </FormContainer>
    );
};

export default AppointmentForm;
