import React, {useEffect, useState} from "react";
import {useMutation, useQuery} from "@apollo/client";
import View from "../Common/View";
import styled from "styled-components";
import {Link, useNavigate, useParams} from "react-router-dom";
import GET_FURNITURE from "../ProductPage/Redux/API/getFurniture";
import client from "../Client/client";
import UPDATE_FURNITURE from "./Redux/API/editFurniture";
import {FaArrowLeft} from "react-icons/fa6";
import {useSelector} from "react-redux";
import GET_MEUBLES_TYPES from "../CreateFurniture/Redux/API/getMeublesTypes";
import ColorsEnum from "../Utils/Enums/Colors";
import {useDropzone} from "react-dropzone";
import MaterialsEnum from "../Utils/Enums/Materials";
import {FaTimesCircle} from "react-icons/fa";
import {palettes} from "../Common/Colors";

const FormContainer = styled.div`
  padding: 20px;
  background: #f7f7f7;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Container = styled.div`
  width: 75%;
  margin: 0 auto;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;

  label {
    display: block;
    font-weight: bold;
  }

  input, textarea {
    width: 95%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
`;

const SubmitButton = styled.button`
  background: ${palettes.primary.dark};
  color: white;
  font-weight: bold;
  font-size: 18px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #1f4d74;
  }
`;

const BackToListLink = styled(Link)`
  display: flex;
  align-items: center;
  margin-top: 15px;
  text-decoration: none;
  color: ${palettes.primary.light};
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;

  & > svg {
    margin-right: 5px;
  }

  &:hover {
    color: #005a9e;
  }
`;

const BackToListText = styled.div`
  display: flex;
  align-items: center;
  margin-top: -2px;
`;

const CharacterCountContainer = styled.div`
  color: #555;
  font-size: 14px;
`;

const SelectField = styled.select`
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: calc(100% - 20px);
  background: #fff; /* Couleur de fond */
  color: #333; /* Couleur du texte */
  transition: border-color 0.2s;

  &:focus {
    outline: none;
    border-color: ${palettes.primary.dark}; /* Couleur de la bordure lorsqu'il est sélectionné */
  }
`;

const SelectOption = styled.option`
  /* Style des options du sélecteur (si nécessaire) */
`;

const ImagePreview = styled.div`
  margin-top: 10px;
`;

const ImageThumbnail = styled.img`
  max-width: 150px; // Ajustez la largeur maximale comme vous le souhaitez
  max-height: 150px; // Ajustez la hauteur maximale comme vous le souhaitez
  margin-right: 10px;
`;

const DropzoneContainer = styled.div`
  border: 2px dashed ${palettes.primary.dark};
  margin-top: 10px;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  z-index: 1;
`;

const DropzoneText = styled.div`
  font-size: 18px;
  color: ${palettes.primary.dark};
  margin-bottom: 10px;
`;

const RemoveIcon = styled.div`
  position: absolute;
  top: -10px;
  right: 0;
  cursor: pointer;
  color: red;
  padding: 4px;
  z-index: 5;
`;

const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  z-index: 5;
`;

const EditFurnitureView = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const userIsLoggedIn = useSelector((state) => state.auth.user);
    const { loading: typesLoading, data: typesData } = useQuery(GET_MEUBLES_TYPES);
    const { refetch } = useQuery(GET_FURNITURE, {
        variables: { id: parseInt(id) },
    });
    useEffect(() => {
        if (!userIsLoggedIn) {
            navigate('/products');
        }
    }, []);
    const [furnitureData, setFurnitureData] = useState({
        nom: "",
        description: "",
        prix: 0.0,
        taille: "",
        image: null,
        images: [],
        meubleType: null,
        couleur: "",   // Champ "couleur"
        materiau: "",  // Champ "materiau"
        etat: "",      // Champ "etat"
    });

    const [remainingCharacters, setRemainingCharacters] = useState(4000);
    const [updateFurniture] = useMutation(UPDATE_FURNITURE);
    const defaultColors = Object.values(ColorsEnum);
    const defaultStates = ['Neuf', 'Bon', 'Passable'];

    useEffect(() => {
        // Utilisez la requête pour obtenir les données du meuble existant
        client.query({query: GET_FURNITURE, variables: {id: parseInt(id)}})
            .then((result) => {
                const existingFurniture = result.data.getFurniture;
                setFurnitureData(existingFurniture);
                const charactersUsed = existingFurniture.description.length;
                const charactersRemaining = 4000 - charactersUsed;
                setRemainingCharacters(charactersRemaining);
            })
            .catch((error) => {
                console.error("Erreur lors de la récupération des données du meuble :", error);
            });
    }, [id]);

    const handleInputChange = (e) => {
        let {name, value} = e.target;
        if (name === 'prix' && !isNaN(parseFloat(value))) {
            value = parseFloat(value);
        }
        setFurnitureData({...furnitureData, [name]: value});

        if (name === 'description') {
            const charactersUsed = value.length;
            const charactersRemaining = 4000 - charactersUsed;
            setRemainingCharacters(charactersRemaining);
        }
    };

    const handleMeubleTypeChange = (e) => {
        const selectedTypeId = parseInt(e.target.value);
        setFurnitureData({ ...furnitureData, meubleType: selectedTypeId });
    };

    const onDropMainImage = (acceptedFiles) => {
        setFurnitureData({ ...furnitureData, image: acceptedFiles[0] });
    };

    const onDropAdditionalImages = (acceptedFiles) => {
        setFurnitureData({ ...furnitureData, images: [...furnitureData.images, ...acceptedFiles] });
    };

    const { getRootProps: getMainImageRootProps, getInputProps: getMainImageInputProps } = useDropzone({
        onDrop: onDropMainImage,
        accept: 'image/*',
    });

    const { getRootProps: getAdditionalImagesRootProps, getInputProps: getAdditionalImagesInputProps } = useDropzone({
        onDrop: onDropAdditionalImages,
        accept: 'image/*',
        multiple: true,
    });

    const handleRemoveImage = (index) => {
        const updatedImages = [...furnitureData.images];
        updatedImages.splice(index, 1);
        setFurnitureData({ ...furnitureData, images: updatedImages });
    };

    const handleRemoveImageClick = (e, index) => {
        e.preventDefault(); // Empêche le comportement par défaut du clic (sélecteur de fichier)
        e.stopPropagation(); // Empêche la propagation de l'événement au conteneur parent
        handleRemoveImage(index);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let meubleType = furnitureData.meubleType
        if (typeof furnitureData?.meubleType !== 'number') {
            meubleType = furnitureData.meubleType.id
        }

        updateFurniture({
            variables: {
                ...furnitureData,
                nom: furnitureData.nom.trim(),
                description: furnitureData.description.trim(),
                id: parseInt(id), // Assurez-vous de transmettre l'ID du meuble à mettre à jour
                meubleType
            },
        })
            .then((result) => {
                refetch()
                navigate(`/products/${id}`);
            })
            .catch((error) => {
                console.error("Erreur lors de la mise à jour du meuble :", error);
            });
    };

    return (
        <View>
            <BackToListLink to={`/products/${id}`}>
                <FaArrowLeft/>
                <BackToListText>Retour au produit</BackToListText>
            </BackToListLink>
            <Container>
                <h1>Édition du produit</h1>
                <FormContainer>
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                        <FormGroup>
                            <label>Nom du meuble:</label>
                            <input
                                type="text"
                                name="nom"
                                value={furnitureData.nom}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Description:</label>
                            <textarea
                                name="description"
                                rows={10}
                                value={furnitureData.description}
                                onChange={handleInputChange}
                                maxLength={4000}
                            />
                            <CharacterCountContainer>
                                {remainingCharacters} caractères restants
                            </CharacterCountContainer>
                        </FormGroup>
                        <FormGroup>
                            <label>Prix:</label>
                            <input
                                type="number"
                                name="prix"
                                value={furnitureData.prix}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Type de meuble:</label>
                            <SelectField
                                name="meubleTypeId"
                                value={furnitureData?.meubleType?.id}
                                onChange={handleMeubleTypeChange}
                            >
                                <SelectOption value="" disabled>Sélectionnez un type de meuble</SelectOption>
                                {typesLoading ? (
                                    <SelectOption>Loading...</SelectOption>
                                ) : (
                                    typesData.getMeublesTypes.map((type) => (
                                        <SelectOption key={type.id} value={type.id}>
                                            {type.nom}
                                        </SelectOption>
                                    ))
                                )}
                            </SelectField>
                        </FormGroup>
                        <FormGroup>
                            <label>Taille:</label>
                            <input
                                type="text"
                                name="taille"
                                value={furnitureData.taille}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Couleur:</label>
                            <SelectField
                                name="couleur"
                                value={furnitureData.couleur}
                                onChange={handleInputChange}
                            >
                                <SelectOption value="" disabled>Sélectionnez une couleur</SelectOption>
                                {typesLoading ? (
                                    <SelectOption>Loading...</SelectOption>
                                ) : (
                                    defaultColors.map((color, index) => (
                                        <SelectOption key={index} value={color}>
                                            {color}
                                        </SelectOption>
                                    ))
                                )}
                            </SelectField>
                        </FormGroup>
                        <FormGroup>
                            <label>Matériau:</label>
                            <SelectField
                                name="materiau"
                                value={furnitureData.materiau}
                                onChange={handleInputChange}
                            >
                                <SelectOption value="" disabled>Sélectionnez un matériau</SelectOption>
                                {typesLoading ? (
                                    <SelectOption>Loading...</SelectOption>
                                ) : (
                                    Object.values(MaterialsEnum).map((material, index) => (
                                        <SelectOption key={index} value={material}>
                                            {material}
                                        </SelectOption>
                                    ))
                                )}
                            </SelectField>
                        </FormGroup>
                        <FormGroup>
                            <label>État:</label>
                            <SelectField
                                name="etat"
                                value={furnitureData.etat}
                                onChange={handleInputChange}
                            >
                                <SelectOption value="" disabled>Sélectionnez un état</SelectOption>
                                {typesLoading ? (
                                    <SelectOption>Loading...</SelectOption>
                                ) : (
                                    defaultStates.map((state, index) => (
                                        <SelectOption key={index} value={state}>
                                            {state}
                                        </SelectOption>
                                    ))
                                )}
                            </SelectField>
                        </FormGroup>
                        <FormGroup>
                            <label>Image principale:</label>
                            <DropzoneContainer {...getMainImageRootProps()}>
                                <input {...getMainImageInputProps()} />
                                <DropzoneText>Faites glisser et déposez l'image principale ici, ou cliquez pour la sélectionner.</DropzoneText>
                                {furnitureData.image && (
                                    <ImagePreview>
                                        <ImageThumbnail src={typeof furnitureData.image === 'object' ? URL.createObjectURL(furnitureData.image) : furnitureData.image} alt="Image principale" />
                                    </ImagePreview>
                                )}
                            </DropzoneContainer>
                        </FormGroup>
                        <FormGroup>
                            <label>Images supplémentaires:</label>
                            <DropzoneContainer {...getAdditionalImagesRootProps()}>
                                <input {...getAdditionalImagesInputProps()} />
                                <DropzoneText>Faites glisser et déposez des images ici, ou cliquez pour sélectionner des fichiers.</DropzoneText>
                                {furnitureData.images.length > 0 && (
                                    <ImagePreview>
                                        {furnitureData.images.map((image, index) => (
                                            <ImageContainer key={index}>
                                                <RemoveIcon onClick={(e) => handleRemoveImageClick(e, index)}>
                                                    <FaTimesCircle />
                                                </RemoveIcon>
                                                <ImageThumbnail src={typeof image === 'object' ? URL.createObjectURL(image) : image} alt={`Image ${index + 1}`} />
                                            </ImageContainer>
                                        ))}
                                    </ImagePreview>
                                )}
                            </DropzoneContainer>
                        </FormGroup>
                        <SubmitButton type="submit">Enregistrer les modifications</SubmitButton>
                    </form>
                </FormContainer>
            </Container>
        </View>
    );
};

export default EditFurnitureView;
