import { gql } from '@apollo/client';

const GET_MEUBLES_TYPES = gql`
  query getMeublesTypes {
    getMeublesTypes {
      id
      nom
    }
  }
`;

export default GET_MEUBLES_TYPES;
