import React, {useState} from 'react';
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import styled from 'styled-components';
import {palettes} from "../../Common/Colors";
import {useMutation, useQuery} from "@apollo/client";
import PURCHASE from "../Redux/API/createCommand";
import {checkPromoCode, purchase} from "../Redux/Actions/productActions";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import CREATE_PAYMENT_INTENT from "../Redux/API/createPaymentIntent";
import CHECK_PROMO_CODE from "../Redux/API/checkPromoCode";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_SECRET_KEY}`);

const Form = styled.form`
  width: 80%;
  margin: 0 auto 30px auto;
  padding: 20px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: #fff;
  text-align: center;
`;

const PaymentButton = styled.button`
  background-color: ${palettes.primary.dark};
  color: white;
  border: none;
  border-radius: 5px;
  padding: 15px 30px;
  font-size: 1.2em;
  cursor: pointer;
  margin-top: 20px;
`;

const ReturnButton = styled(Link)`
  display: block;
  width: 80%;
  text-decoration: none;
  background-color: ${palettes.primary.light};
  color: white;
  border: none;
  border-radius: 5px;
  padding: 15px 30px;
  font-size: 1.2em;
  cursor: pointer;
  margin: auto;
  transition: background-color 0.3s; /* Ajout de la transition */

  &:hover {
    background-color: ${palettes.primary.dark}; /* Couleur de fond au survol */
  }
`;

const PromoCodeContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

const PromoCodeInput = styled.input`
  width: 95%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
`;

const PromoCodeLabel = styled.label`
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
    text-align: left;
`;

const PromoCodeButton = styled.button`
  background-color: ${palettes.primary.dark};
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 1em;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 15px; 
`;

const StripePaymentForm = ({
                             totalAmount,
                             onNextStep,
                             selectedDate,
                             isDeliveryChecked,
                             deliveryInfo,
                             customerInfo,
                             furnitures
                           }) => {
  const user = useSelector((state) => state.auth.user);
  const stripe = useStripe();
  const elements = useElements();
  const [sendCommand] = useMutation(PURCHASE);
  const [createPaymentIntent] = useMutation(CREATE_PAYMENT_INTENT);
  const dispatch = useDispatch();
  const [error, setError] = useState()
  const [errorPromo, setErrorPromo] = useState()
  const [okPromo, setOkPromo] = useState('')
  const [stripeError, setStripeError] = useState()
  const [promoCode, setPromoCode] = useState('');
  const { refetch } = useQuery(CHECK_PROMO_CODE, {
    variables: {
      userId: user.id,
      promoCode: promoCode
    }
  })
  const [finalTotalAmount, setFinalTotalAmount] = useState(totalAmount);

  const handlePromoCodeCheck = async () => {
    try {
      const { loading, error, data } = await refetch()

      if (loading) {
        return;
      }

      if (error) {
        // Gérer les erreurs si nécessaire
        setErrorPromo(error);
        return;
      }

      if (data) {
        setErrorPromo(null)
        setOkPromo('Code promo activé')
        totalAmount = (totalAmount * (1 - data.checkPromoCode.discount / 100)).toFixed(2)
        dispatch(checkPromoCode(totalAmount));
        setFinalTotalAmount(totalAmount)
      }
    } catch (error) {
      setErrorPromo(error);
    }
  };

  const handlePayment = async (e) => {
    e.preventDefault();

    if (elements == null) {
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setStripeError(submitError.message);
      return;
    }

    try {
      // Appelez votre mutation GraphQL pour créer une intention de paiement
      const { data } = await createPaymentIntent({
        variables: {
          userId: user.id,
          amount: finalTotalAmount * 100,
          name: customerInfo.name,
          firstname: customerInfo.firstname,
          email: customerInfo.email,
          promoCode: promoCode
        },
      });

      const { clientSecret, customerId } = data.createPaymentIntent;

      const { error } = await stripe.confirmPayment({
        clientSecret,
        elements,
        confirmParams: {
          return_url: 'http://localhost:3000/payment'
        },
        redirect: "if_required",
      });
      if (error) {
        setStripeError(error);
        // Gérez l'erreur de manière appropriée (affichage à l'utilisateur, journalisation, etc.)
        return;
      }

      try {
        // Appelez la mutation GraphQL
        // Assurez-vous que votre mutation accepte les nouvelles informations, y compris le token de paiement
        await sendCommand({
          variables: {
            name: customerInfo.name,
            firstname: customerInfo.firstname,
            email: customerInfo.email,
            phone: customerInfo.phone,
            address: isDeliveryChecked ? deliveryInfo.address : null,
            city: isDeliveryChecked ? deliveryInfo.city : null,
            stripeCustomerId: customerId ?? null,
            date: selectedDate,
            isDeliveryChecked: isDeliveryChecked,
            furniture: furnitures.map(el => el.id)
          },
        });
        dispatch(purchase());

        // Passez à l'étape suivante ou effectuez d'autres actions
        onNextStep();
      } catch (error) {
        setError(error)
        // Gérez l'erreur de manière appropriée
      }
    } catch (error) {
      setError(error);
    }
  };

  return (
    <Form onSubmit={handlePayment}>
      <PromoCodeContainer>
        <PromoCodeLabel htmlFor="promoCode">Code Promo</PromoCodeLabel>
        <PromoCodeInput
          type="text"
          value={promoCode}
          onChange={(e) => setPromoCode(e.target.value)}
          placeholder="Entrez votre code promo"
        />
        <PromoCodeButton type="button" onClick={handlePromoCodeCheck}>Vérifier le code</PromoCodeButton>
        {
          errorPromo && <>
            <p style={{fontSize: 18, color: 'red'}}>
              {errorPromo?.graphQLErrors?.length > 0 ? errorPromo.graphQLErrors[0]?.extensions.debugMessage : errorPromo}</p>
          </>
        }
        {
          okPromo && <p style={{fontSize: 18, color: 'green'}}>{okPromo}</p>
        }
      </PromoCodeContainer>
      <PaymentElement/>
      <PaymentButton disabled={!stripe} type="submit">Payer {finalTotalAmount} €</PaymentButton>
      {
        error && <>
          <p style={{fontSize: 18, color: 'red'}}>Une erreur s'est produite
            : {error?.graphQLErrors?.length > 0 ? error.graphQLErrors[0]?.extensions.debugMessage : error}</p>
          <ReturnButton to="/products" onClick={() => dispatch(purchase())}>Retour à la page des produits</ReturnButton>
        </>
      }
      {
        stripeError && <>
          <p style={{fontSize: 18, color: 'red'}}>Une erreur s'est produite
            : {stripeError}</p>
          <ReturnButton to="/products" onClick={() => dispatch(purchase())}>Retour à la page des produits</ReturnButton>
        </>
      }
    </Form>
  )
    ;
};

const StripeWrapper = ({
                         totalAmount,
                         onNextStep,
                         selectedDate,
                         isDeliveryChecked,
                         deliveryInfo,
                         customerInfo,
                         furnitures
                       }) => {
  return (
  <Elements stripe={stripePromise} options={{
    mode: 'payment',
    amount: totalAmount && totalAmount > 0 ? parseFloat(totalAmount).toFixed(2) * 100 : 100,
    currency: 'eur',
    appearance: {
      theme: 'stripe',
    }
  }}>
    <StripePaymentForm
      totalAmount={totalAmount}
      onNextStep={onNextStep}
      selectedDate={selectedDate}
      isDeliveryChecked={isDeliveryChecked}
      deliveryInfo={deliveryInfo}
      customerInfo={customerInfo}
      furnitures={furnitures}
    />
  </Elements>
)};

export default StripeWrapper;
