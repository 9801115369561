import React, {useEffect, useRef, useState} from 'react';
import styled, {keyframes} from 'styled-components';
import View from '../Common/View';
import {BsChevronDoubleDown} from "react-icons/bs";
import ContactUs from "../Common/Lotties/ContactUs";
import Prepare from "../Common/Lotties/Prepare";
import Move from "../Common/Lotties/Move";
import Sell from "../Common/Lotties/Sell";
import Money from "../Common/Lotties/Money";

const PageContainer = styled.div`
  background: linear-gradient(to bottom, #ffffff, #66ccff, #3399ff, #0066cc); /* Dégradé de couleur sur toute la page avec un départ de blanc */
  min-height: 100vh; /* Remplit toute la hauteur de la fenêtre */
`;

const HeroSection = styled.section`
  text-align: center;
  margin-bottom: 20px;
  padding: 15% 0;
  position: relative;
  transition: background 0.5s; /* Transition en douceur du changement de fond */
`;

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
`;

const ArrowIcon = styled.div`
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  width: 35px;
  margin: -20px auto;
  padding: 5px 10px 0 10px;
  transform: translateX(-50%);
  font-size: 2em;
  border: 2px solid black;
  border-radius: 100%;
  color: black;
  cursor: pointer;
  animation: ${bounce} 1.5s infinite;
`;

const HeroHeading = styled.h1`
  font-size: 3.5em;
  margin-bottom: 10px;
  color: #333; /* Couleur du texte en haut */
`;

const HeroDescription = styled.p`
  font-size: 1.75em;
  margin-bottom: 20px;
  color: #666; /* Couleur du texte en haut */
`;

const TextContainer = styled.h2`
  margin: ${props => (props.isOdd ? '0 10% 0 15%' : '0 15% 0 10%')};
  color: white;
`;

const FeatureHeading = styled.h2`
  font-size: 1.5em;
  margin-bottom: 15px;

  @media (max-width: 1000px) {
    font-size: 1em;
  }
`;

const FeatureDescription = styled.p`
  font-size: 1em;
  margin-bottom: 20px;

  @media (max-width: 1000px) {
    font-size: 0.75em;
  }
`;

const FeatureSection = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 25px;
  gap: ${props => props.isMobile ? '0px' : '50px'};
  align-items: center;
  justify-items: center;
  text-align: left;

  &:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const HowItWorks = () => {
    const [sectionIntersected, setSectionIntersected] = useState([false, false, false, false, false]);
    const sectionRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '20%',
            threshold: 0.8,
        };

        const sectionObservers = sectionRefs.map((sectionRef, index) => {
            return new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    setSectionIntersected((prev) => {
                        const updatedState = [...prev];
                        updatedState[index] = true;
                        return updatedState;
                    });
                } else {
                    setSectionIntersected((prev) => {
                        const updatedState = [...prev];
                        updatedState[index] = false;
                        return updatedState;
                    });
                }
            }, observerOptions);
        });

        sectionRefs.forEach((sectionRef, index) => {
            if (sectionRef.current) {
                sectionObservers[index].observe(sectionRef.current);
            }
        });

        return () => {
            sectionObservers.forEach((observer) => observer.disconnect());
        };
    }, []);

    const scrollDown = () => {
        window.scrollTo({
            top: window.innerHeight,
            behavior: 'smooth',
        });
    };

    const isMobile = () => {
        return window.innerWidth <= 768;
    };

    return (
        <View>
            <PageContainer>
                <HeroSection>
                    <HeroHeading>Comment ça marche</HeroHeading>
                    <HeroDescription>
                        Découvrez notre processus étape par étape pour vendre vos meubles rapidement et facilement.
                    </HeroDescription>
                    <ArrowIcon onClick={scrollDown}><BsChevronDoubleDown/></ArrowIcon>
                </HeroSection>

                <FeatureSection isMobile={isMobile()} isOdd ref={sectionRefs[0]} sectionIntersected={sectionIntersected[0]}>
                    <ContactUs/>
                    <TextContainer isOdd={!isMobile()}>
                        <FeatureHeading>Contactez-nous et prenez rendez-vous</FeatureHeading>
                        <FeatureDescription>
                            Lors de cet appel, indiquez-nous la quantité de meubles que vous souhaitez vendre ainsi que le prix auquel vous les estimez.<br/><br/>
                            Sur notre plateforme, nous utilisons un système de ventes au cadran où nous établissons un prix initial et un prix minimal
                            pour la vente de vos meubles. La vente commence au prix maximal, puis tous les 5 jours, le prix est réduit progressivement
                            afin de favoriser une vente rapide de votre mobilier.
                        </FeatureDescription>
                    </TextContainer>
                </FeatureSection>

                <FeatureSection isMobile={isMobile()} ref={sectionRefs[1]} sectionIntersected={sectionIntersected[1]}>
                    {isMobile() && <Prepare/>}
                    <TextContainer>
                        <FeatureHeading>Préparez vos meubles</FeatureHeading>
                        <FeatureDescription>
                            Prenez au moins sept photos de vos meubles après les avoir soigneusement nettoyés et vérifié leur état.
                            Ensuite, assurez-vous de les protéger pour le transport. <br/><br/>
                            Si vous préférez que nous nous chargions de cette protection,
                            veuillez noter que des frais de manutention supplémentaires seront appliqués.
                        </FeatureDescription>
                    </TextContainer>
                    {!isMobile() && <Prepare/>}
                </FeatureSection>

                <FeatureSection isMobile={isMobile()} isOdd ref={sectionRefs[2]} sectionIntersected={sectionIntersected[2]}>
                    <Move/>
                    <TextContainer isOdd={!isMobile()}>
                        <FeatureHeading>On vient les chercher</FeatureHeading>
                        <FeatureDescription>
                            Notre équipe de transport assure la récupération de vos meubles sur place afin de les entreposer dans nos locaux.
                            Veuillez descendre les meubles à l'extérieur de votre domicile,
                            ou informez-nous si vous préférez que nous les retirions à l'intérieur
                            (des frais de manutention différents peuvent s'appliquer).
                        </FeatureDescription>
                    </TextContainer>
                </FeatureSection>

                <FeatureSection isMobile={isMobile()} ref={sectionRefs[3]} sectionIntersected={sectionIntersected[3]}>
                    {isMobile() && <Sell/>}
                    <TextContainer>
                        <FeatureHeading>On s'occupe de les vendre</FeatureHeading>
                        <FeatureDescription>
                            Nous commercialisons et vendons vos meubles sur notre plateforme en ligne,
                            prenant en charge l'intégralité du processus de la relation avec l'acheteur ainsi que de la livraison.
                        </FeatureDescription>
                    </TextContainer>
                    {!isMobile() && <Sell/>}
                </FeatureSection>

                <FeatureSection isMobile={isMobile()} isOdd ref={sectionRefs[4]} sectionIntersected={sectionIntersected[4]}>
                    <Money/>
                    <TextContainer isOdd={!isMobile()}>
                        <FeatureHeading>Votre bénéfice directement versée sur votre compte</FeatureHeading>
                        <FeatureDescription>
                            Dès qu'un de vos meubles est vendu, vous recevrez une notification par e-mail,
                            et le montant de votre bénéfice sera directement transféré sur votre compte.
                        </FeatureDescription>
                    </TextContainer>
                </FeatureSection>
            </PageContainer>
        </View>
    );
};

export default HowItWorks;
