import React from 'react';
import Lottie from 'lottie-react';
import animationData from './lotties/Move.json';

const Move = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true
    };

    return (
        <div style={styles.container}>
            <Lottie
                options={defaultOptions}
                animationData={animationData}
                height={'auto'}
                width={'100%'}
            />
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%', // Ajustez la largeur selon vos besoins
        padding: '10% 0',
        background: 'white', // Couleur de fond
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', // Ombre
        borderRadius: '10px', // Coins arrondis
        // Ajoutez d'autres styles CSS personnalisés selon vos préférences
    },
};

export default Move;
