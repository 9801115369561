import React, { useState } from 'react';
import { FaCheck } from "react-icons/fa6";
import { ButtonDelete, ButtonSave, Check, SortOption, SortOptions } from "./CategoryStyle";
import ColorsEnum from '../../Utils/Enums/Colors';

const Color = ({ setCategoryStates, toggleCategory, fetchProducts }) => {
    const defaultColors = Object.values(ColorsEnum); // Utilisez les valeurs de l'enum

    const [selectedColors, setSelectedColors] = useState([]);

    const handleColorChange = (color) => {
        if (selectedColors.includes(color)) {
            setSelectedColors(selectedColors.filter((c) => c !== color)); // Retire la couleur si elle est déjà sélectionnée
        } else {
            setSelectedColors([...selectedColors, color]); // Ajoute la couleur si elle n'est pas encore sélectionnée
        }
        // Fermer la catégorie "Trier" lors de la sélection d'une option
        setCategoryStates((prevState) => ({
            ...prevState,
            couleur: false,
        }));
    };

    return (
        <React.Fragment>
            <SortOptions>
                {defaultColors.map((color) => (
                    <SortOption key={color} onClick={() => handleColorChange(color)}>
                        {color}
                        {selectedColors.includes(color) && <Check><FaCheck /></Check>}
                    </SortOption>
                ))}
            </SortOptions>
            <div>
                <ButtonDelete
                    onClick={(e) => {
                        e.stopPropagation();
                        setSelectedColors([]);
                    }}
                >
                    Effacer
                </ButtonDelete>
                <ButtonSave
                    onClick={(e) => {
                        e.stopPropagation();
                        toggleCategory('couleur');
                        fetchProducts({ couleur: selectedColors });
                    }}
                >
                    Enregistrer
                </ButtonSave>
            </div>
        </React.Fragment>
    );
};

export default Color;
