import { createStore, applyMiddleware, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {thunk} from 'redux-thunk';
import authReducer from "../Common/Redux/Reducer/authReducer";
import productReducer from "../ProductPage/Redux/Reducer/productReducer";
import getOrdersReducer from "../Order/Redux/Reducer/getOrdersReducer";

const rootReducer = combineReducers({
    auth: authReducer,
    products: productReducer,
    orders: getOrdersReducer,
});

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'products'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  applyMiddleware(thunk)  // Appliquer le middleware thunk lors de la création du magasin
);

const persistor = persistStore(store);

export { store, persistor };
