import { gql } from '@apollo/client';

export const UPDATE_FURNITURE = gql`
mutation editFurniture(
  $id: Int!
  $nom: String
  $description: String
  $prix: Float
  $taille: String
  $image: Upload
  $images: [Upload]
  $meubleType: Int
  $couleur: String
  $etat: String
  $materiau: String
) {
  editFurniture(
    id: $id
    nom: $nom
    description: $description
    prix: $prix
    taille: $taille
    image: $image
    images: $images
    meubleType: $meubleType
    couleur: $couleur
    etat: $etat
    materiau: $materiau
  ) {
      id
      nom
      description
      prix
      taille
      image
      images
      meubleType {
        id
        nom # Si vous souhaitez récupérer des détails sur le type de meuble
      }
      disponible
      couleur
      etat
      materiau
      # Autres champs du meuble que vous souhaitez récupérer
  }
}
`;

export default UPDATE_FURNITURE;
