import React, {useState} from 'react';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import View from '../Common/View';
import OurHistory from "../Common/Lotties/OurHistory";
import Engagement from "../Common/Lotties/Engagement";
import Values from "../Common/Lotties/Values";
import Vision from "../Common/Lotties/Vision";
import Contact from "../Common/Lotties/Contact";
import {palettes} from "../Common/Colors";

const Header = styled.header`
  text-align: center;
  margin-bottom: 20px;
  padding: 40px 0;
  background-color: #ffffff; /* Fond blanc pour la partie header */
`;

const HeaderTitle = styled.h1`
  font-size: 2.5em;
  color: #333;
`;

const AboutContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 colonnes égales */
  justify-items: center;
  gap: 20px;
  padding: 20px;

    @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr); /* 3 colonnes égales */
    }
`;

const AboutSection = styled.section`
  padding: 20px;
  background-color: ${props => props.backgroundColor || '#f9f9f9'};
  margin-bottom: 20px;
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 7px 7px 4px 4px rgba(51, 153, 255, 0.15);
  color: ${props => (props.backgroundColor === '#273746' ? '#fff' : '#333')};
  text-align: center;
`;

const AboutHeading = styled.h2`
  font-size: 1.5em;
  margin-bottom: 10px;
`;

const AboutContent = styled.p`
  font-size: 1.1em;
  margin-bottom: 15px;
`;

const Avatar = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
  margin-top: 20px;
`;

const Button = styled.button`
  display: block;
  margin: 30px auto;
  padding: 15px 30px;
  font-size: 1.2em;
  background-color: ${palettes.primary.light};
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${palettes.primary.dark};
  }
`;

const About = () => {
    const navigate = useNavigate();
    // Créez un tableau d'états isHovered pour chaque animation Lottie
    const [isHovered, setIsHovered] = useState([false, false, false, false, false]);

    const handleHover = (index) => {
        const updatedIsHovered = [...isHovered];
        updatedIsHovered[index] = !updatedIsHovered[index];
        setIsHovered(updatedIsHovered);
    };

    return (
        <View>
            <Header>
                <HeaderTitle>Qui Sommes-Nous</HeaderTitle>
            </Header>
            <AboutContainer>
                <AboutSection backgroundColor="#f6fafb" onMouseEnter={() => handleHover(0)} onMouseLeave={() => handleHover(0)}>
                    <OurHistory isHovered={isHovered[0]}/>
                    <AboutHeading>Notre Histoire</AboutHeading>
                    <AboutContent>
                        Nous avons fondé JDM dans le but d'offrir un service aux individus qui souhaitent vendre leurs
                        meubles mais qui manquent d'espace, de temps, ou de motivation pour le faire eux-mêmes.
                        Notre engagement est de fournir un service complet, accompagné de meubles de qualité,
                        afin de répondre pleinement aux attentes et ainsi satisfaire nos clients.
                    </AboutContent>
                </AboutSection>

                <AboutSection backgroundColor="#273746">
                    <Avatar src="/assets/avatar.jpg" alt="Avatar" />
                    <AboutHeading>Lenny - Le Créateur</AboutHeading>
                    <AboutContent>
                        Lenny est le créateur passionné derrière JDM Meubles. Son amour pour le mobilier et son
                        désir de créer une entreprise axée sur la durabilité et la qualité ont été les moteurs de
                        la création de JDM Meubles.
                    </AboutContent>
                </AboutSection>

                <AboutSection backgroundColor="#f6fafb" onMouseEnter={() => handleHover(1)} onMouseLeave={() => handleHover(1)}>
                    <Engagement isHovered={isHovered[1]} />
                    <AboutHeading>Notre Engagement</AboutHeading>
                    <AboutContent>
                        Nous favorisons la durabilité en donnant une seconde vie aux meubles, réduisant ainsi le gaspillage et l'empreinte carbone.
                        Opter pour des meubles d'occasion soutient un mode de vie durable, tout en offrant une solution de don en cas d'invendu.
                    </AboutContent>
                </AboutSection>

                <AboutSection backgroundColor="#273746" onMouseEnter={() => handleHover(2)} onMouseLeave={() => handleHover(2)}>
                    <Values isHovered={isHovered[2]} />
                    <AboutHeading>Nos Valeurs</AboutHeading>
                    <div>
                        <p>
                            <div style={{fontWeight: "bold", fontSize: '1.2em', marginBottom: 5}}>Qualité</div>
                            Nous nous engageons à fournir de la qualité autant dans notre service que dans notre mobilier.
                        </p>
                        <p>
                            <div style={{fontWeight: "bold", fontSize: '1.2em', marginBottom: 5}}>Durabilité</div>
                            Nous croyons en la durabilité et nous nous efforçons de prolonger la vie des meubles tout en respectant l’environnement.
                        </p>
                        <p>
                            <div style={{fontWeight: "bold", fontSize: '1.2em', marginBottom: 5}}>Service Client</div>
                            Nous visons à vous offrir un service complet et qualitatif avec un suivi de A à Z à chaque étape de votre parcours.
                        </p>
                    </div>
                </AboutSection>

                <AboutSection backgroundColor="#f6fafb" onMouseEnter={() => handleHover(3)} onMouseLeave={() => handleHover(3)}>
                    <Vision isHovered={isHovered[3]} />
                    <AboutHeading>Notre Vision</AboutHeading>
                    <AboutContent>
                        <div style={{fontWeight: "bold", fontSize: '1.2em', marginBottom: 10}}>Devenir un leader dans le mobilier d'occasion</div>
                        <div>
                            <i>“Équiper un foyer grâce à la seconde main permet d’éviter l’équivalent de plus de 3,5 tonnes de CO2, soit un tour du monde complet en avion”</i>
                            <br/><br/>
                            Opter pour l’achat de meubles d’occasion, participer à la promotion d’un mode de vie plus durable et à la préservation de l’environnement.
                            Notre perspective est de proposer à nos clients d'acheter de l’occasion tout en étant conscient de l’impact environnemental.
                        </div>
                    </AboutContent>
                </AboutSection>

                <AboutSection backgroundColor="#273746" onMouseEnter={() => handleHover(4)} onMouseLeave={() => handleHover(4)}>
                    <Contact isHovered={isHovered[4]} />
                    <AboutHeading>Contactez-nous</AboutHeading>
                    <AboutContent>
                        Vous avez des questions ou besoin d'informations supplémentaires ?
                        <Button onClick={() => navigate('/contact')}>Nous Contacter</Button>
                    </AboutContent>
                </AboutSection>
            </AboutContainer>
        </View>
    );
};

export default About;
