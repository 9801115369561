import { gql } from '@apollo/client';

// Définissez votre mutation GraphQL pour créer un utilisateur
export const EDIT_USER = gql`
    mutation editUser($id: Int!, $name: String, $email: String, $password: String) {
      editUser(
        id: $id,
        name: $name,
        email: $email,
        password: $password
    ) {
        id
        name
        email,
        role
        # Autres champs de l'utilisateur que vous souhaitez récupérer
      }
    }
`;

export default EDIT_USER;
