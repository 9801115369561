import { gql } from '@apollo/client';

export const CHECK_PROMO_CODE = gql`
  query checkPromoCode(
    $userId: Int!
    $promoCode: String
  ) {
    checkPromoCode(
      userId: $userId
      promoCode: $promoCode
    ) {
      discount
    }
  }
`;

export default CHECK_PROMO_CODE;
