import React, {useState} from 'react';
import styled from 'styled-components';
import FilterCategory from './FilterCategory';
import Filter from "./Category/Filter";
import Color from "./Category/Color";
import Price from "./Category/Price";
import State from "./Category/State";
import GET_FURNITURES from "./Redux/API/getFurnitures";
import client from "../Client/client";
import Type from "./Category/Type";

const Filters = styled.div`
  background-color: #f1f1f1;
  display: flex;
  flex-direction: row;
  margin: 20px 0 -40px 0;
  padding: 20px;
  justify-content: center;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  height: 50px;
`;

const ProductFilters = ({ setProducts }) => {
    const [openCategory, setOpenCategory] = useState(null);
    const [, setPopupOpen] = useState(false);

    const [categoryStates, setCategoryStates] = useState({
        trier: false,
        couleur: false,
        prix: false,
        etat: false,
        type: false,
    });

    const toggleCategory = (category) => {
        setCategoryStates((prevState) => ({
            ...prevState,
            [category]: !prevState[category],
        }));
        if (openCategory && openCategory !== category) {
            // Ferme la popup de la catégorie précédente
            setCategoryStates((prevState) => ({
                ...prevState,
                [openCategory]: false,
            }));
        }
        setPopupOpen(false); // Ferme la popup
        setOpenCategory(category);
    }

    const fetchProducts = async (tri) => {
        try {
            // Effectuer l'appel GraphQL
            const { data } = await client.query({
                query: GET_FURNITURES,
                variables: tri,
            });

            // Mettez à jour la liste des produits avec les données reçues
            setProducts(data.getFurnitures);
        } catch (error) {
            console.error('Erreur lors de l\'appel GraphQL :', error);
        }
    };

    return (
        <Filters>
            <FilterCategory
                name="Trier"
                isOpen={categoryStates.trier}
                togglePopup={() => toggleCategory('trier')}
                setPopupOpen={setPopupOpen}
                openCategory={openCategory}
            >
                <Filter fetchProducts={fetchProducts} />
            </FilterCategory>
            <FilterCategory
                name="Couleur"
                isOpen={categoryStates.couleur}
                togglePopup={() => toggleCategory('couleur')}
                setPopupOpen={setPopupOpen}
                openCategory={openCategory}
                second
            >
                <Color setCategoryStates={setCategoryStates} toggleCategory={toggleCategory} fetchProducts={fetchProducts} />
            </FilterCategory>
            <FilterCategory
                name="Prix"
                isOpen={categoryStates.prix}
                togglePopup={() => toggleCategory('prix')}
                setPopupOpen={setPopupOpen}
                openCategory={openCategory}
            >
                <Price toggleCategory={toggleCategory} fetchProducts={fetchProducts} />
            </FilterCategory>
            <FilterCategory
                name="État"
                isOpen={categoryStates.etat}
                togglePopup={() => toggleCategory('etat')}
                setPopupOpen={setPopupOpen}
                openCategory={openCategory}
                second
            >
                <State fetchProducts={fetchProducts} />
            </FilterCategory>
            <FilterCategory
                name="Type"
                isOpen={categoryStates.type}
                togglePopup={() => toggleCategory('type')}
                setPopupOpen={setPopupOpen}
                openCategory={openCategory}
            >
                <Type setCategoryStates={setCategoryStates} toggleCategory={toggleCategory} fetchProducts={fetchProducts} />
            </FilterCategory>
        </Filters>
    );
};

export default ProductFilters;
