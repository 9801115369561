// MenuBurger.js
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {palettes} from "../Colors";

const BurgerMenuContainer = styled.div`
  position: absolute;
  top: 74px;
  left: 94px;
  transform: translateX(-50%);
  background-color: ${palettes.primary.dark}; // Couleur du fond du menu
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  z-index: 1000;
  border-bottom-right-radius: 5px;
  text-align: center;
  width: max-content;

  ul {
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    width: 100%;
  }

  a {
    display: block;
    text-decoration: none;
    color: white;
    font-size: 18px;
    font-weight: bold;
    padding: 15px;
  }

  a:hover {
    background: ${palettes.primary.light};
  }
`;

const BurgerMenu = ({ isOpen, onClose, links }) => {
  return (
    <BurgerMenuContainer isOpen={isOpen}>
      <ul>
        {links.map((link) => (
          <li key={link.to}>
            <Link to={link.to} onClick={onClose}>
              {link.label}
            </Link>
          </li>
        ))}
      </ul>
    </BurgerMenuContainer>
  );
};

export default BurgerMenu;
