import React from 'react';
import styled from 'styled-components';
import {IoIosArrowDropleftCircle, IoIosArrowDroprightCircle} from 'react-icons/io';
import {FaTimes} from 'react-icons/fa';
import {palettes} from "../../Common/Colors";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: transparent;
  padding: 20px;
  width: 80%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (max-width: 825px) {
    max-width: 350px;
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;
`;

const StyledImage = styled.img`
  max-width: 100%;
  max-height: 80vh;
`;

const NavigationButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 36px;
  color: #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${({ direction }) => (direction === 'prev' ? 'left: -75px;' : 'right: -75px;')}

  &:hover {
    color: #005a9e;
  }

  @media (max-width: 825px) {
    top: 110%;
    width: 75px;
    height: 75px;
    font-size: 50px;
    ${({ direction }) => (direction === 'prev' ? 'left: -5px;' : 'right: -5px;')}
  }
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 40px;
  color: ${palettes.primary.light};
  position: absolute;
  top: 22px;
  right: 29px;

  &:hover {
    color: ${palettes.primary.dark};
  }
`;

const FullscreenImageModal = ({ imageUrl, onClose, onPrev, onNext }) => {
    return (
        <ModalOverlay onClick={onClose}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <ImageContainer>
                    <NavigationButton direction="prev" onClick={onPrev}>
                        <IoIosArrowDropleftCircle/>
                    </NavigationButton>
                    <StyledImage src={imageUrl} alt="Fullscreen" />
                    <NavigationButton direction="next" onClick={onNext}>
                        <IoIosArrowDroprightCircle/>
                    </NavigationButton>
                </ImageContainer>
            </ModalContent>
            <CloseButton onClick={onClose}><FaTimes/></CloseButton>
        </ModalOverlay>
    );
};

export default FullscreenImageModal;
