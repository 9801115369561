import React, {useState} from 'react';
import styled from 'styled-components';
import {FaTimesCircle} from 'react-icons/fa';
import LOGIN_USER from '../Redux/API/loginUser';
import {useMutation} from '@apollo/client';
import {useDispatch} from 'react-redux';
import {loginSuccess} from "../Redux/Actions/auhtActions";
import {palettes} from "../Colors";

const PopupContent = styled.div`
  position: absolute;
  top: 90px;
  right: 3%;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 7px 7px 7px rgba(51, 153, 255, 0.15);
  border-radius: 5px;
  padding: 20px 40px;
  z-index: 1000;
  min-width: 350px;
`;

const LoginTitle = styled.h2`
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  margin-top: 0;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const InputField = styled.input`
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
`;

const SubmitButton = styled.button`
  background: ${props => (props.disabled ? '#ccc' : palettes.primary.light)};
  color: ${props => (props.disabled ? '#666' : 'white')};
  border: none;
  border-radius: 5px;
  padding: 10px;
  font-size: 18px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: background 0.3s, color 0.3s;

  &:hover {
    background: ${props => (props.disabled ? '#ccc' : palettes.primary.dark)};
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 1.4em;
  color: red;
  cursor: pointer;
`;

const ErrorContainer = styled.div`
  color: red;
  margin-top: 10px;
`;

const Login = ({isOpen, onClose}) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const [loginUser] = useMutation(LOGIN_USER, {
    onCompleted: (data) => {
      const userData = data.loginUser;
      dispatch(loginSuccess(userData));
      onClose();
      setError(null);
      setLoading(false); // Arrêtez le chargement une fois la connexion réussie
    },
    onError: (error) => {
      const errorMessage = error.graphQLErrors[0]?.extensions.debugMessage || 'Erreur inattendue. Veuillez réessayer.';
      setError(errorMessage);
      setLoading(false); // Arrêtez le chargement en cas d'erreur
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true); // Démarrez le chargement lorsque le formulaire est soumis
    loginUser({
      variables: {
        email: username,
        password: password,
      },
    }).then((r) => console.log(r));
  };

  return isOpen ? (
    <PopupContent id="login-popup" onClick={(e) => e.stopPropagation()}>
      <CloseButton onClick={() => {
        onClose()
        setError(null);
      }}>
        <FaTimesCircle/>
      </CloseButton>
      <LoginTitle>Se Connecter</LoginTitle>
      <Form onSubmit={handleSubmit}>
        <label htmlFor="username">Adresse mail</label>
        <InputField
          type="email"
          placeholder="Adresse mail"
          required
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <label htmlFor="password">Mot de passe</label>
        <InputField
          type="password"
          placeholder="Mot de passe"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <SubmitButton disabled={loading}>{loading ? 'Chargement...' : 'Connexion'}</SubmitButton>
      </Form>
      {error && <ErrorContainer>{error}</ErrorContainer>}
    </PopupContent>
  ) : null;
};

export default Login;
