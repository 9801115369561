import React, {useState} from 'react';
import Slider from "rc-slider";
import {ButtonDelete, ButtonSave, SortOptions} from "./CategoryStyle";
import styled from "styled-components";
import 'rc-slider/assets/index.css';

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const InputField = styled.input`
  padding: 5px;
  margin-top: 3px;
  border: none;
  width: 55px;

  &:focus {
    outline: none; // Supprimer la bordure du focus
  }
`;

const PriceInput = styled.div`
  position: relative;
  display: flex;
  width: 75px;
  align-items: center;
  margin-right: 10px;
  border: 1px solid #ccc;
  padding: 5px;

  &::before {
    content: "€";
    position: absolute;
    right: 5px;
    color: #555;
  }

  &:first-child {
    margin-right: 10px;
  }

  &:last-child {
    margin-left: 10px;
  }
`;

const Price = ({toggleCategory, fetchProducts}) => {
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(5000);
    return (
        <React.Fragment>
            <SortOptions onClick={(e) => e.stopPropagation()}>
                <div onClick={(e) => e.stopPropagation()}>
                    <InputContainer>
                        <PriceInput>
                            <InputField
                                type="number"
                                value={minPrice}
                                onChange={(e) => setMinPrice(Number(e.target.value))}
                                onClick={(e) => e.stopPropagation()}
                            />
                        </PriceInput>
                        -
                        <PriceInput>
                            <InputField
                                type="number"
                                value={maxPrice}
                                onChange={(e) => setMaxPrice(Number(e.target.value))}
                                onClick={(e) => e.stopPropagation()}
                            />
                        </PriceInput>
                    </InputContainer>
                </div>
                <div style={{width: '75%', padding: 15, marginLeft: 15, marginRight: 15}}>
                    <Slider
                        min={0}
                        max={5000}
                        step={10}
                        range
                        value={[minPrice, maxPrice]}
                        onChange={(value) => {
                            setMinPrice(value[0]);
                            setMaxPrice(value[1]);
                        }}
                        onClick={(e) => e.stopPropagation()}
                    />
                </div>
            </SortOptions>
            <div>
                <ButtonDelete
                    onClick={(e) => {
                        e.stopPropagation();
                        setMinPrice(0);
                        setMaxPrice(5000);
                    }}
                >
                    Effacer
                </ButtonDelete>
                <ButtonSave
                    onClick={(e) => {
                        e.stopPropagation();
                        toggleCategory('prix');
                        fetchProducts({minPrix: minPrice, maxPrix: maxPrice})
                    }}
                >
                    Enregistrer
                </ButtonSave>
            </div>
        </React.Fragment>
    );
};

export default Price;
