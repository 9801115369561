// Cart.js

import React from 'react';
import styled from 'styled-components';
import { FaTrash } from 'react-icons/fa6';
import {palettes} from "../Colors";
import {useSelector} from "react-redux";

const PopupContainer = styled.div`
  position: absolute;
  top: 90px;
  right: 3%;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 7px 7px 7px rgba(51, 153, 255, 0.15);
  border-radius: 5px;
  padding: 10px 20px;
  z-index: 1000;
  min-width: 250px; /* Ajustez la largeur minimale selon vos besoins */
`;

const ProductItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const ProductImage = styled.img`
  width: 75px;
  height: auto;
  margin-right: 8px;
  border-radius: 3px;
`;

const ProductInfo = styled.div`
  flex-grow: 1;
`;

const ProductName = styled.span`
  font-weight: bold;
  display: block;
  margin-bottom: 4px;
`;

const ProductDetails = styled.span`
  color: #555;
  font-size: 14px;
  display: block;
`;

const CheckoutButton = styled.button`
  width: 100%;
  padding: 10px;
  background: ${palettes.primary.light};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 10px;
  font-size: 1.2em;

  &:hover {
    background: ${palettes.primary.dark};
  }
`;

const DeleteButton = styled.button`
  background: #e74c3c; /* Couleur rouge */
  color: white;
  border: none;
  margin-left: 10px;
  padding: 7px;
  cursor: pointer;
  border-radius: 3px;
  transition: background 0.3s;
  display: flex;
  align-items: center;
  font-size: 18px;

  &:hover {
    background: #c0392b; /* Couleur rouge foncé au survol */
  }
`;

const TotalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;
`;

const TotalLabel = styled.span`
  font-weight: bold;
`;

const TotalValue = styled.span`
  font-size: 20px;
  font-weight: bold;
`;

const EmptyCartMessage = styled.p`
  text-align: center;
  color: #555;
`;

const Cart = ({ cartItems, onCheckout, onDelete }) => {
  const totalAmount = useSelector(state => state.products.cart.totalAmount);
  const total = totalAmount && totalAmount !== 0 ? parseFloat(totalAmount) : cartItems.reduce((acc, item) => acc + item.prix * item.quantity, 0);

  return (
    <PopupContainer id="cart-popup">
      <h3 style={{textAlign: "center", borderBottom: '1px solid #ccc', paddingBottom: 10, marginTop: 0}}>Mon Panier</h3>
      {cartItems.length === 0 ? (
        <EmptyCartMessage>Le panier est vide.</EmptyCartMessage>
      ) : (
        <div>
          {cartItems.map(item => (
            <ProductItem key={item.id}>
              <ProductImage src={item.image} alt={item.nom} />
              <ProductInfo>
                <ProductName>{item.nom}</ProductName>
                <ProductDetails>{item.prix.toFixed(2)}€</ProductDetails>
              </ProductInfo>
              <DeleteButton onClick={(e) => onDelete(item.id, e)}>
                <FaTrash />
              </DeleteButton>
            </ProductItem>
          ))}
          <TotalContainer>
            <TotalLabel>Total</TotalLabel>
            <TotalValue>{total.toFixed(2)}€</TotalValue>
          </TotalContainer>
          <CheckoutButton onClick={onCheckout}>Payer</CheckoutButton>
        </div>
      )}
    </PopupContainer>
  );
};

export default Cart;
