import { gql } from '@apollo/client';

export const CREATE_PAYMENT_INTENT = gql`
  mutation createPaymentIntent(
    $userId: Int!
    $amount: Int!
    $name: String!
    $firstname: String!
    $email: String!
    $promoCode: String
    # Ajoutez d'autres champs nécessaires pour créer un client Stripe
  ) {
    createPaymentIntent(
      userId: $userId
      amount: $amount
      name: $name
      firstname: $firstname
      email: $email
      promoCode: $promoCode
      # Ajoutez d'autres champs nécessaires pour créer un client Stripe
    ) {
      clientSecret,
    }
  }
`;

export default CREATE_PAYMENT_INTENT;
