// actions/Actions.js

// Action type pour enregistrer les données du meuble
export const GET_FURNITURES_SUCCESS = 'GET_FURNITURES_SUCCESS';

// Action creator pour récupérer les informations des meubles dans le store
export const getFurnituresSuccess = (furnitures) => {
    return {
        type: GET_FURNITURES_SUCCESS,
        furnitures: furnitures,
    };
};

// Action type pour récupérer les données du meuble
export const GET_FURNITURE_SUCCESS = 'GET_FURNITURE_SUCCESS';

// Action creator pour stocker les informations du meuble dans le store
export const getFurnitureSuccess = (furniture) => {
    return {
        type: GET_FURNITURE_SUCCESS,
        furniture: furniture,
    };
};

export const ADD_TO_CART = 'ADD_TO_CART';
export const addToCart = (productInfo) => {
    return {
        type: 'ADD_TO_CART',
        payload: productInfo,
    };
};

export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const removeFromCart = (itemId) => {
    return {
        type: 'REMOVE_FROM_CART',
        payload: itemId,
    };
};

export const PURCHASE = 'PURCHASE';
export const purchase = () => {
    return {
        type: 'PURCHASE'
    };
};

export const CREATE_PAYMENT_INTENT = 'CREATE_PAYMENT_INTENT';
export const createPaymentIntent = (amount) => {
    return {
        type: 'CREATE_PAYMENT_INTENT',
        payload: amount,
    };
};

export const CHECK_PROMO_CODE = 'CHECK_PROMO_CODE';
export const checkPromoCode = (promoCode) => {
    return {
        type: 'CHECK_PROMO_CODE',
        payload: promoCode,
    };
};


