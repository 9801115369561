import React from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import styled from 'styled-components';
import View from '../../Common/View';
import {TbBuildingWarehouse, TbRulerMeasure} from 'react-icons/tb';
import {AiOutlineLike} from 'react-icons/ai';
import {IoColorPaletteOutline} from 'react-icons/io5';
import {HiOutlineClipboardList} from 'react-icons/hi';
import {FaArrowLeft} from 'react-icons/fa6';
import Carousel from './Carousel';
import {useMutation, useQuery} from "@apollo/client";
import GET_FURNITURE from "../Redux/API/getFurniture";
import {useDispatch, useSelector} from "react-redux";
import Spinner from "../../Common/Spinner";
import DELETE_FURNITURE from "../Redux/API/deleteFurniture";
import {addToCart} from "../Redux/Actions/productActions";
import {palettes} from "../../Common/Colors";


const ProductDetailsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding: 20px;
`;

const ProductDetailsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  max-width: 1200px;
  width: 100%;
`;

const LeftSection = styled.div`
  flex: 1;
  margin-right: 20px;
`;

const RightSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;

    @media (max-width: 825px) {
        margin-top: 10%;
        max-width: max-content;
    }
`;

const ProductImagesCarousel = styled.div`
  max-width: 700px;
  margin: auto;

  @media (max-width: 1110px) {
    max-width: 500px;
  }

  @media (max-width: 900px) {
    max-width: 350px;
  }

  @media (max-width: 825px) {
    max-width: 300px;
  }
`;

const ProductTitle = styled.h2`
  font-size: 30px;
  margin-bottom: 10px;
`;

const ProductPrice = styled.p`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
`;

const ProductSectionTitle = styled.h3`
  font-size: 20px;
  margin-bottom: 10px;
`;

const ProductDescription = styled.p`
  font-size: 16px;
  margin-bottom: 20px;
`;

const ProductCharacteristics = styled.div`
  font-size: 16px;
  display: grid;
  grid-template-columns: auto auto auto; /* Utilisation de max-content pour le titre */
  gap: 30px 20px;
`;

const ProductCharacteristicItem = styled.div`
  display: flex;
  align-items: center; /* Aligner verticalement le contenu */
`;

const CharacteristicIcon = styled.div`
  margin: 5px 10px auto 0; /* Espacement entre l'icône et le texte */
  font-size: 20px;
`;

const InfoCard = styled.div`
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 80%;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

const CardTitle = styled.h3`
  margin: 0 0 10px 0;
`;

const ActionButton = styled.button`
  padding: 10px 0;
  width: 100%;
  font-size: 16px;
  background-color: ${({primary}) => (primary ? '#27ae60' : palettes.primary.light)};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: ${({primary}) => (primary ? '#218c53' : palettes.primary.dark)};
  }
`;

const Separator = styled.hr`
  border: none;
  height: 1px;
  background: #eaeaea; /* Couleur de la ligne de séparation */
  margin: 10px 0; /* Espacement autour de la ligne de séparation */
`;

const BackToListLink = styled(Link)`
  display: flex;
  align-items: center;
  margin-top: -25px;
  text-decoration: none;
  color: ${palettes.primary.light};
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;

  & > svg {
    margin-right: 5px;
  }

  &:hover {
    color: ${palettes.primary.dark};
  }
`;

const BackToListText = styled.div`
  display: flex;
  align-items: center;
  margin-top: -2px;
`;

const EditButton = styled.button`
  padding: 10px 0;
  width: 100%;
  height: 50px;
  font-size: 16px;
  background-color: ${palettes.primary.light};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: ${palettes.primary.dark};
  }
`;

const DeleteButton = styled.button`
  padding: 10px 0;
  width: 100%;
  height: 50px;
  font-size: 16px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #c0392b;
  }
`;

const Price = styled.div`
  display: flex;
`;

const Delivery = styled.p`
  margin-left: 10px;
    border-radius: 15px;
    background-color: ${palettes.primary.light};
    color: white;
    padding: 5px 10px;
`;

const ProductDetails = () => {
    const dispatch = useDispatch();
    const {id} = useParams();
    const navigate = useNavigate();
    const userIsLoggedIn = useSelector((state) => state.auth.user);
    const {loading, error, data} = useQuery(GET_FURNITURE, {
        variables: {id: parseInt(id)}, // Passez l'ID comme variable
    });
    const [deleteFurnitureMutation] = useMutation(DELETE_FURNITURE);
    const product = data?.getFurniture;

    if (loading) {
        return <View><p style={{marginLeft: 15}}>Chargement en cours du produit...</p><Spinner/></View>;
    }

    if (error) {
        return <View><p>Une erreur s'est produite : {error.message}</p></View>;
    }

    const handleEditClick = () => {
        navigate(`/edit/${id}`);
    };

    const handleDeleteClick = () => {
        // Appelez la mutation deleteFurniture avec l'ID du produit à supprimer
        deleteFurnitureMutation({
            variables: {
                id: parseInt(id), // Assurez-vous de passer l'ID correct du produit
            },
        })
            .then((result) => {
                const success = result.data.deleteFurniture;
                if (success) {
                    // La suppression a réussi, vous pouvez rediriger l'utilisateur vers une autre page
                    navigate('/products');
                } else {
                    // La suppression a échoué, vous pouvez gérer l'erreur ici
                    console.error("La suppression du meuble a échoué.");
                }
            })
            .catch((error) => {
                // Gérez les erreurs de la mutation ici
                console.error("Erreur lors de la suppression du meuble :", error);
            });
    };

    const handleBuyNowClick = async () => {
        // Stocker les informations du produit dans le store
        const productInfo = {
            id: product.id,
            nom: product.nom,
            prix: product.prix,
            image: product.image,
        };
        dispatch(addToCart(productInfo));
        navigate('/payment')
    };

    const handleAddToBasket = async () => {
        const productInfo = {
            id: product.id,
            nom: product.nom,
            prix: product.prix,
            image: product.image,
        };
        dispatch(addToCart(productInfo));
    };
    const isFromYouProducts = window.location.pathname.includes('/yourproducts')

    return (
        <View>
            <ProductDetailsContainer>
                <ProductDetailsWrapper>
                    <LeftSection>
                        <BackToListLink to={!isFromYouProducts ? '/products' : '/yourproducts'}>
                            <FaArrowLeft/>
                            <BackToListText>Retour à la liste des produits</BackToListText>
                        </BackToListLink>
                        {
                            product?.image.length > 0 &&
                            <ProductImagesCarousel>
                                <Carousel images={[product.image, ...product.images]}/>
                            </ProductImagesCarousel>
                        }
                        <ProductTitle>{product.nom}</ProductTitle>
                        <Price>
                            <ProductPrice>{product.prix.toFixed(2)} €</ProductPrice>
                            <Delivery>Livraison possible</Delivery>
                        </Price>
                        <Separator/> {/* Séparation après le carrousel */}
                        <ProductSectionTitle>Description</ProductSectionTitle>
                        <ProductDescription>{product.description}</ProductDescription>
                        <Separator/> {/* Séparation après le carrousel */}
                        <ProductSectionTitle>Caractéristiques</ProductSectionTitle>
                        <ProductCharacteristics>
                            <ProductCharacteristicItem>
                                <CharacteristicIcon>
                                    <AiOutlineLike/>
                                </CharacteristicIcon>
                                <div>
                                    <div style={{textAlign: 'left', fontSize: '0.9em'}}>Condition</div>
                                    <div style={{textAlign: 'left'}}>{product.etat ?
                                        'En bon état' : 'Usagé'}</div>
                                </div>
                            </ProductCharacteristicItem>
                            <ProductCharacteristicItem>
                                <CharacteristicIcon>
                                    <TbRulerMeasure/>
                                </CharacteristicIcon>
                                <div>
                                    <div style={{textAlign: 'left', fontSize: '0.9em'}}>Dimensions</div>
                                    <div style={{textAlign: 'left'}}>{product.taille || '-'}</div>
                                </div>
                            </ProductCharacteristicItem>
                            <ProductCharacteristicItem>
                                <CharacteristicIcon>
                                    <TbBuildingWarehouse/>
                                </CharacteristicIcon>
                                <div>
                                    <div style={{textAlign: 'left', fontSize: '0.9em'}}>Type</div>
                                    <div style={{textAlign: 'left'}}>{product.meubleType.nom || '-'}</div>
                                </div>
                            </ProductCharacteristicItem>
                            <ProductCharacteristicItem>
                                <CharacteristicIcon>
                                    <IoColorPaletteOutline/>
                                </CharacteristicIcon>
                                <div>
                                    <div style={{textAlign: 'left', fontSize: '0.9em'}}>Couleur</div>
                                    <div style={{textAlign: 'left'}}>{product.couleur || '-'}</div>
                                </div>
                            </ProductCharacteristicItem>
                            <ProductCharacteristicItem>
                                <CharacteristicIcon>
                                    <HiOutlineClipboardList/>
                                </CharacteristicIcon>
                                <div>
                                    <div style={{textAlign: 'left', fontSize: '0.9em'}}>Matériel</div>
                                    <div style={{textAlign: 'left'}}>{product.materiau || '-'}</div>
                                </div>
                            </ProductCharacteristicItem>
                        </ProductCharacteristics>
                    </LeftSection>

                    {!isFromYouProducts && <RightSection>
                        <InfoCard>
                            <CardTitle>Ce meuble vous plaît ?</CardTitle>
                            <ActionButton primary onClick={handleBuyNowClick}>Acheter maintenant</ActionButton>
                            <ActionButton onClick={handleAddToBasket}>Ajouter au panier</ActionButton>
                            <ActionButton onClick={() => navigate(`/contact/${id}`)}>Demandez des
                                informations</ActionButton>
                        </InfoCard>
                        {userIsLoggedIn && userIsLoggedIn.role === 'admin' && (
                            <InfoCard>
                                <CardTitle>Modifier le meuble</CardTitle>
                                <EditButton onClick={handleEditClick}>Accéder au mode édition</EditButton>
                                <DeleteButton onClick={handleDeleteClick}>Supprimer le meuble</DeleteButton>
                            </InfoCard>
                        )}
                    </RightSection>}
                </ProductDetailsWrapper>
            </ProductDetailsContainer>
        </View>
    );
};

export default ProductDetails;
