import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import {setContext} from "@apollo/client/link/context";
import {store} from "./store";

const corsOptions = {
    origin: '*',
    methods: ['POST', 'GET', 'PATCH', 'DELETE', 'OPTIONS'],
    allowedHeaders: ['Content-Type', 'Authorization'],
};

const httpLink = createUploadLink({
    uri: 'https://api.jungledumonde.com/graphql',
});

const authLink = setContext((_, { headers }) => {
    const token = store.getState().auth.token;
    return {
        headers: {
            ...headers,
            ...corsOptions,
            authorization: token ?`Bearer ${token}` : '',
        },
    };
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});

export default client;
