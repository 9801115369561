import React, {useState} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styled from 'styled-components';
import FullscreenImageModal from './FullscreenImageModal';
import {palettes} from "../../Common/Colors";

const CarouselContainer = styled.div`
  margin: 20px 0;
  
  .slick-dots li button:before {
    color: ${palettes.primary.light}; // Couleur des points indicateurs
  }

  .slick-prev:before {
    color: ${palettes.primary.light}; // Couleur des flèches de navigation
    font-size: 30px; // Ajuster la taille des flèches
    margin-left: -10px;
  }

  .slick-next:before {
    color: ${palettes.primary.light}; // Couleur des flèches de navigation
    font-size: 30px; // Ajuster la taille des flèches
  }

  .slick-slide img {
    width: 100%; // Ajuster la largeur des images à 100%
    border-radius: 5px; // Ajouter une bordure arrondie aux images
  }

  .slick-list {
    margin: 0 -5px;
  }

  .slick-slide > div {
    padding: 0 5px;
  }
`;

const SlideImage = styled.img`
  width: 100%;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
`;

const SeePhotoButton = styled.div`
  position: relative;
  width: fit-content;
  left: 82%;
  bottom: 50px;
  background-color: #fff;
  color: #000000;
  padding: 5px 10px;
  border-radius: 50px;
  cursor: pointer;
  font-weight: bold;
  pointer-events: none;

    @media (max-width: 825px) {
        left: 60%;
    }
`;

const Carousel = ({ images }) => {
    const [fullscreenImageUrl, setFullscreenImageUrl] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);

    const openFullscreenImage = (imageUrl, index) => {
        setFullscreenImageUrl(imageUrl);
        setCurrentIndex(index);
    };

    const closeFullscreenImage = () => {
        setFullscreenImageUrl(null);
    };

    const goToPrevImage = () => {
        const prevIndex = (currentIndex - 1 + images.length) % images.length;
        setCurrentIndex(prevIndex);
        setFullscreenImageUrl(images[prevIndex]);
    };

    const goToNextImage = () => {
        const nextIndex = (currentIndex + 1) % images.length;
        setCurrentIndex(nextIndex);
        setFullscreenImageUrl(images[nextIndex]);
    };

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: images.length > 1 ? 2 : 1,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <CarouselContainer>
            <Slider {...settings}>
                {images?.map((image, index) => (
                    <div key={index}>
                        <SlideImage
                            src={image}
                            alt={`Slide ${index + 1}`}
                            onClick={() => openFullscreenImage(image, index)}
                        />
                    </div>
                ))}
            </Slider>
            <SeePhotoButton>Voir la photo</SeePhotoButton>
            {fullscreenImageUrl && (
                <FullscreenImageModal
                    imageUrl={fullscreenImageUrl}
                    onClose={closeFullscreenImage}
                    onPrev={goToPrevImage}
                    onNext={goToNextImage}
                />
            )}
        </CarouselContainer>
    );
};

export default Carousel;
