import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import Login from "./Login";
import { useDispatch, useSelector } from "react-redux";
import {FaBars, FaBasketShopping, FaPowerOff, FaUser} from "react-icons/fa6";
import {closeCart, logout} from "../Redux/Actions/auhtActions";
import MenuBurger from "./MenuBurger";
import {FaTimes} from "react-icons/fa";
import Cart from "./Cart";
import {removeFromCart} from "../../ProductPage/Redux/Actions/productActions";
import Profile from "./Profile";
import {palettes} from '../Colors'

const NavContainer = styled.div`
  background: ${palettes.primary.dark};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 75px;
`;

const NavMenu = styled.nav`
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  li {
    display: flex;
    margin-left: 20px;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    border-radius: 5px;
    transition: background 0.3s;
  }

  a {
    color: white;
    text-decoration: none;
    padding: 10px 20px;
  }

  li:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  li.active {
    background: rgba(255, 255, 255, 0.2);
  }

  @media (max-width: 1350px) {
    li {
      margin-left: 0;
    }

    a {
      font-size: 16px;
      padding: 5px 10px;
    }
  }

  @media (max-width: 1050px) {
    li {
      margin-left: 10px;
    }

    a {
      font-size: 14px;
      padding: 5px 0;
    }
  }

  @media (max-width: 825px) {
    display: none;
  }
`;

const LogoContainer = styled.div`
  height: 65px;
  margin: 0 30px 0 15px;
  background-color: white;
  border: 2px solid ${palettes.primary.dark};
  border-radius: 7px;

  @media (max-width: 1100px) {
    display: none;
  }
`;

const Logo = styled.img`
  height: 65px;
  padding: 0 5px;
`;

const RightButtons = styled.div`
  display: flex;
  align-items: center;
`;

const LeftButtons = styled.div`
  display: flex;
  align-items: center;
`;

const ConnectButton = styled.button`
  padding: 10px 15px;
  background: none;
  border: 1px solid white;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  margin-right: 20px;

  &:hover {
    background: white;
    color: ${palettes.primary.dark}; // Couleur d'accent
  }

  @media (max-width: 1350px) {
    padding: 5px 10px;
    font-size: 14px;
  }
`;

const WelcomeMessage = styled.span`
  color: white;
  font-weight: bold;
  font-size: 18px;
  margin-right: 20px;
  width: max-content;
`;

const LogoutButton = styled.button`
  margin-right: 15px;
  padding: 10px 12px;
  background: ${palettes.primary.dark}; // Couleur d'accent
  color: white;
  border: 1px solid white; // Couleur d'accent
  border-radius: 5px;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background: red;
    border: 1px solid red; // Couleur d'accent
  }

  @media (max-width: 1350px) {
    padding: 5px 7px;
    font-size: 14px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const BurgerMenuButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: none; // Masquer le bouton par défaut sur les écrans plus larges
  color: white;
  font-size: 24px;
  margin: 20px;

  @media (max-width: 825px) {
    display: block; // Afficher le bouton sur les écrans plus étroits (mobiles)
  }
`;

const CartButton = styled.button`
  position: relative;
  padding: 7px 12px;
  background: none;
  border: 1px solid white;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
  margin-right: 20px;

  &:hover {
    background: white;
    color: ${palettes.primary.dark}; // Couleur d'accent
  }

  @media (max-width: 1350px) {
    padding: 5px 10px;
    font-size: 14px;
  }
`;

const CartBadge = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: white;
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 50%;
`;

const Navbar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const cartItems = useSelector(state => state.products.cart.cartItems);
  const cartOpen = useSelector(state => state.products.cart.open);
  const [isLoginPopupOpen, setLoginPopupOpen] = useState(false);
  const [isBurgerMenuOpen, setBurgerMenuOpen] = useState(false);
  const [isCartPopupOpen, setCartPopupOpen] = useState(false);
  const [isProfilePopupOpen, setProfilePopupOpen] = useState(false);

  const openLoginPopup = () => {
    setLoginPopupOpen(true);
  };

  const closeLoginPopup = () => {
    setLoginPopupOpen(false);
  };

  const user = useSelector((state) => state.auth.user);

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleCheckout = () => {
    navigate('/payment')
    dispatch(closeCart(false));
  };

  const handleDeleteItem = (itemId, e) => {
    e.stopPropagation()
    dispatch(removeFromCart(itemId));
  };

  const closePopups = () => {
    setLoginPopupOpen(false);
    setCartPopupOpen(false);
    setProfilePopupOpen(false);
    dispatch(closeCart(false));
  };

  // Ajoutez le gestionnaire d'événements lorsque le composant est monté
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        (isCartPopupOpen && !event.target.closest('#cart-popup')) ||
        (isProfilePopupOpen && !event.target.closest('#profile-popup')) ||
        (isLoginPopupOpen && !event.target.closest('#login-popup'))
      ) {
        closePopups();
      }
    };
    setCartPopupOpen(cartOpen)

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isLoginPopupOpen, isCartPopupOpen, isProfilePopupOpen, cartOpen]);

  return (
    <NavContainer>
      <LeftButtons>
        <LogoContainer>
          <a href={'/products'}>
            <Logo src={'/logo900x330.png'}/>
          </a>
        </LogoContainer>
        <BurgerMenuButton onClick={() => setBurgerMenuOpen(!isBurgerMenuOpen)}>
          {
            isBurgerMenuOpen ? <FaTimes/> : <FaBars/>
          }
        </BurgerMenuButton>

        {isBurgerMenuOpen && (
          <MenuBurger
            isOpen={isBurgerMenuOpen}
            onClose={() => setBurgerMenuOpen(false)}
            links={[
              { to: '/products', label: 'Nos Produits' },
              { to: '/howitworks', label: 'Comment Vendre' },
              { to: '/contact', label: 'Nous Contacter' },
              { to: '/about', label: 'Qui Sommes-Nous' },
              // Ajoutez d'autres liens selon vos besoins
            ]}
          />
        )}
        <NavMenu>
          <ul>
            <li className={location.pathname === '/products' ? 'active' : ''}><Link to="/products">Nos Produits</Link></li>
            <li className={location.pathname === '/howitworks' ? 'active' : ''}><Link to="/howitworks">Comment Vendre</Link></li>
            <li className={location.pathname === '/contact' ? 'active' : ''}><Link to="/contact">Nous Contacter</Link></li>
            <li className={location.pathname === '/about' ? 'active' : ''}><Link to="/about">Qui Sommes-Nous</Link></li>
            {
              user && user.role === 'admin' && (
                <>
                  <li className={location.pathname === '/add' ? 'active' : ''}><Link to="/add">Ajouter Un Produit</Link></li>
                  <li className={location.pathname === '/order' ? 'active' : ''}><Link to="/order">Bon De Commandes</Link></li>
                </>
              )
            }
            {
              user && user.role !== 'admin' && <li className={location.pathname === '/yourproducts' ? 'active' : ''}><Link to="/yourproducts">Vos Produit</Link></li>
            }
          </ul>
        </NavMenu>
      </LeftButtons>
      <RightButtons>
        {user ? (
          <ButtonContainer>
            <WelcomeMessage>Bienvenue, {user.name}</WelcomeMessage>
            <CartButton onClick={(e) => {
              e.stopPropagation()
              closePopups()
              setProfilePopupOpen(!isProfilePopupOpen)
            }}><FaUser/></CartButton>
            {isProfilePopupOpen && (
              <Profile user={user} />
            )}
            <CartButton onClick={(e) => {
              e.stopPropagation()
              closePopups()
              setCartPopupOpen(!isCartPopupOpen)
              dispatch(closeCart(!isCartPopupOpen));
            }}>
              <FaBasketShopping />
              <CartBadge>{cartItems.length}</CartBadge>
            </CartButton>
            {isCartPopupOpen && (
              <Cart cartItems={cartItems} onCheckout={handleCheckout} onDelete={handleDeleteItem} />
            )}
            <LogoutButton onClick={handleLogout}>
              <FaPowerOff/>
            </LogoutButton>
          </ButtonContainer>
        ) : (
          <div>
            <CartButton onClick={(e) => {
              e.stopPropagation()
              closePopups()
              setCartPopupOpen(!isCartPopupOpen)
              dispatch(closeCart(!isCartPopupOpen));
            }}>
              <FaBasketShopping />
              <CartBadge>{cartItems.length}</CartBadge>
            </CartButton>
            {isCartPopupOpen && (
              <Cart cartItems={cartItems} onCheckout={handleCheckout} onDelete={handleDeleteItem} />
            )}
            <ConnectButton onClick={(e) => {
              e.stopPropagation()
              closePopups()
              openLoginPopup()
            }}>Se connecter</ConnectButton>
          </div>
        )}
      </RightButtons>
      <Login isOpen={isLoginPopupOpen} onClose={closeLoginPopup} />
    </NavContainer>
  );
};

export default Navbar;
