import { gql } from '@apollo/client';

export const CREATE_FURNITURE = gql`
mutation createFurniture(
  $utilisateur: String!
  $email: String!
  $nom: String!
  $description: String!
  $prix: Float!
  $taille: String!
  $categorie_id: Int!
  $image: Upload
  $images: [Upload]
  $couleur: String
  $etat: String
  $materiau: String
) {
  createFurniture(
    utilisateur: $utilisateur
    email: $email
    nom: $nom
    description: $description
    prix: $prix
    taille: $taille
    categorie_id: $categorie_id
    image: $image
    images: $images
    couleur: $couleur
    etat: $etat
    materiau: $materiau
  ) {
    id
    nom
    description
    prix
    taille
    meubleType {
      id
      nom
    }
    image
    images
    couleur
    etat
    materiau
  }
}
`;

export default CREATE_FURNITURE;
