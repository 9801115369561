import React from 'react';
import Navbar from './Navbar';

const View = ({ children }) => {
    return (
        <div style={{marginBottom: -20}}>
            <header>
                <Navbar />
            </header>
            {children}
        </div>
    );
};

export default View;
