import { gql } from '@apollo/client';

export const PURCHASE = gql`
  mutation purchase($date: String!, $name: String!, $firstname: String!, $email: String!, $phone: String!, $address: String, $city: String, $stripeCustomerId: String, $furniture: [Int!]!) {
    createCommand(date: $date, name: $name, firstname: $firstname, email: $email, phone: $phone, address: $address, city: $city, stripeCustomerId: $stripeCustomerId, furniture: $furniture) {
      id
    }
  }
`;

export default PURCHASE;
