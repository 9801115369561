// enums/MaterialsEnum.js

const MaterialsEnum = {
    WOOD: 'Bois',
    METAL: 'Métal',
    PLASTIC: 'Plastique',
    GLASS: 'Verre',
    OTHER: 'Autre', // Ajoutez d'autres matériaux si nécessaire
};

export default MaterialsEnum;
