// src/Utils/Enums/Colors.js

const ColorsEnum = {
    Rouge: 'Rouge',
    Bleu: 'Bleu',
    Vert: 'Vert',
    Jaune: 'Jaune',
    Noir: 'Noir',
    Blanc: 'Blanc',
    Marron: 'Marron',
    Gris: 'Gris',
};

export default ColorsEnum;
